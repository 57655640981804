import {WebTracerProvider} from "@opentelemetry/sdk-trace-web";
import api, {Tracer, TracerProvider, trace, context, propagation} from "@opentelemetry/api";
import {SDKRegistrationConfig} from "@opentelemetry/sdk-trace-base";
import {WebTracerConfig} from "@opentelemetry/sdk-trace-web/build/src/WebTracerProvider";
import {MTTracer} from "./client";


export class MTTracerProvider implements TracerProvider {

    private _internalProvider: WebTracerProvider;
    private _sessionId: string;
    private readonly _tracers: Map<string, MTTracer> = new Map();


    constructor(internalProvider: WebTracerProvider) {
        this._internalProvider = internalProvider;
        this._sessionId = '';
    }

    register(config?: SDKRegistrationConfig) {
        this._internalProvider.register(config);
        trace.setGlobalTracerProvider(this);
        if (config?.contextManager !== undefined && config?.contextManager !== null) {
            context.setGlobalContextManager(config?.contextManager)
        }

    }

    getTracer(
        name: string,
        version?: string,
        options?: { schemaUrl?: string }
    ): Tracer {
        const tracer = this._internalProvider.getTracer(name, version, options);
        if (!this._tracers.has(name)) {
            this._tracers.set(
                name,
                new MTTracer(tracer, name)
            );
        }
        return tracer;
    }

    getMTTracer(name: string, id?: string) {
        if (!this._tracers.has(name)) {
            const tracer = this._internalProvider.getTracer(name);
            this._tracers.set(
                name,
                new MTTracer(tracer, name, this._sessionId)
            );
        }

        if (id !== undefined) {
            this.setSessionId(id);
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._tracers.get(name)!;
    }

    setSessionId(id: string) {
        if (this._sessionId !== id && id !== undefined) {
            this._sessionId = id;
            this._tracers.forEach((tracer: MTTracer, name: string) => {
                tracer.setSessionId(id);
            });
        }
    }
}