import { ConfigGetter } from "./configGetter";
import {
  CreateReactAppCompiledRuntimeVariableGetter,
  LocalStorageRuntimeVariableGetterAndSetter,
  OrderedRuntimeVariableGetter,
  WindowGlobalRuntimeVariableGetter,
} from "./runtimeVariableGetter";

const localStorageGetterAndSetter =
  new LocalStorageRuntimeVariableGetterAndSetter("MT_");

export const runtimeVariableGetter = new OrderedRuntimeVariableGetter([
  localStorageGetterAndSetter,
  new WindowGlobalRuntimeVariableGetter("MT_ENV"),
  new CreateReactAppCompiledRuntimeVariableGetter(),
]);

export const configGetter = new ConfigGetter(runtimeVariableGetter);
export const runtimeVariableSetter = localStorageGetterAndSetter;
