import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useConfigContext, useDebugLogger } from "../webappConfig";
import { useTracing } from "..";

export interface IEnvironment {
  slug: string;
  controlPlane: string;
  app: string;
  engine: string;
}

export const useEnvironmentsList = () => {
  const { debugLog } = useDebugLogger();
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();
  const { getAccessTokenSilently } = useAuth0();
  const [environments, setEnvironments] = React.useState<IEnvironment[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useCallback(async () => {
    setLoading(true);

    const data = await window.fetch(`${API_BASE_URL}/hub/environments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    });

    const json = await data.json();
    setEnvironments(
      Object.keys(json).map((key) => ({
        slug: key,
        ...json[key],
      }))
    );
    setLoading(false);
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    fetch,
    loading,
    environments,
  };
};
