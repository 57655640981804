import * as React from "react";

export const bold = { style: { fontWeight: "bold" } };

export function useList<T>(initial: T[] = []) {
  const [contents, set] = React.useState<T[]>([]);

  const get = () => contents;

  const add = (item: T) => {
    set((existing) => [...existing, item]);
  };

  const remove = (toRemove: T) => {
    set((existing) => existing.filter((item) => item !== toRemove));
  };

  const contains = (item: T) => {
    return contents.indexOf(item) > -1;
  };

  return { add, remove, set, get, contains };
}

export function filterNotNull<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((item): item is T => item !== null && item !== undefined);
}

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};