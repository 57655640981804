import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, LinearProgress } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { AuthorizationStatus, useEnvironmentAuthCheck } from "../../hooks/auth";
import { FullScreenError } from "../FullScreenError";
import { useConfigContext } from "../../webappConfig";

export const AuthRequired: React.FC = withAuthenticationRequired((props) => {
  const { webAppConfig } = useConfigContext();
  const environment = webAppConfig.environment();

  const [authStatus, setAuthStatus] =
    React.useState<AuthorizationStatus | null>(null);
  const [loading, setLoading] = React.useState(false);

  const { check } = useEnvironmentAuthCheck();

  const performCheck = async () => {
    setLoading(true);
    setAuthStatus(await check());
    setLoading(false);
  };

  React.useEffect(() => {
    performCheck();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading && authStatus === AuthorizationStatus.Allow) {
    return (
      <Box>
        <Outlet />
      </Box>
    );
  }

  if (!loading && authStatus === AuthorizationStatus.Deny) {
    return (
      <FullScreenError
        id="authError"
        message={`You do not have access to ${environment}`}
      />
    );
  }

  return <LinearProgress />;
});
