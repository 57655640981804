import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./LoginButton";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { blueSolidColor } from "./SiteContainer";

export const Profile: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [accessToken, setAccessToken] = React.useState("");

  const loadToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      loadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated && user ? (
    <Box marginTop={2}>
      <img src={user.picture} alt={user.name} />
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <TextField fullWidth multiline value={accessToken} />
    </Box>
  ) : (
    <LoginButton />
  );
};
