import {useAuth0} from "@auth0/auth0-react";
import {
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Table,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import moment from "moment";
import * as React from "react";
import {useParams} from "react-router-dom";
import {
  Session,
  useReconnectingWebsocket,
  useWebsocketReady,
} from "../hooks/session";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useConfigContext} from "../webappConfig";
import {useTracing} from "../index";

interface ISessionEventsResponse {
  events: IEvent[];
  timing: {
    requestToEngineReadyMs: number | null;
  };
}

export const useSessionEvents = () => {
  const {webAppConfig} = useConfigContext();
  const {tracerProvider} = useTracing();
  const apiBaseUrl = webAppConfig.apiBaseUrl();
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();

  const get = async (
    id: string,
    namespace: string
  ): Promise<ISessionEventsResponse> => {
    const tracer = tracerProvider?.getMTTracer("WebApp", id);
    const span = tracer?.startSpan("getSessionEvents");
    const response = await fetch(
      `${apiBaseUrl}/sessions/${namespace}/${id}/events`,
      {
        headers: isAuthenticated
          ? {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          }
          : {},
      }
    );
    const json = (await response.json()) as any;
    const events = json.events.map((e: string) => JSON.parse(e) as IEvent);
    span?.end();
    return {
      events,
      timing: {
        requestToEngineReadyMs: json.timing.requestToEngineReadyMs,
      },
    };
  };

  return {
    get,
  };
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  minWidth: 800,
};

export const Debug: React.FC = (props) => {
  const {webAppConfig} = useConfigContext();
  const environment = webAppConfig.environment();
  const {id, namespace} = useParams<{ id: string; namespace: string }>();
  const [, setSessionReady] = React.useState(false);
  const [secret] = React.useState("");
  const {get: getEvents} = useSessionEvents();
  const [session, setSession] = React.useState<Session | null>(null);
  const [logs, setLogs] = React.useState<ISessionEventsResponse | null>();
  const [selectedEvent, setSelectedEvent] = React.useState<any | null>(null);

  if (!id) throw Error("missing id");
  if (!namespace) throw Error("missing namespace");

  const {poll} = useWebsocketReady(() => setSessionReady(true));

  const {connect} = useReconnectingWebsocket({id, namespace}, (message) => {
    switch (message.type) {
      case "SessionExists":
        setSession(message.session);
        break;
      default:
        break;
    }
  });

  const {tracerProvider, isTracingEnabled} = useTracing();
  tracerProvider?.setSessionId(id);
  const tracer = tracerProvider?.getMTTracer("WebRTCClient", id);

  const span = tracer?.startSpan("Debug");

  React.useEffect(() => {
    connect(span);
    if (secret) {
      poll(secret);
    }
  }, [connect, poll, secret]);

  const loadLogs = async () => {
    setLogs(await getEvents(id, namespace));
  };

  React.useEffect(() => {
    loadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, namespace]);

  const logsLink = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.project_id%3D%22mirrortab-envs%22%0Aresource.labels.location%3D%22us-central1%22%0Aresource.labels.cluster_name%3D%22${environment}-mt-gke%22%0Aresource.labels.namespace_name%3D%22default%22%0Alabels.k8s-pod%2Fapp%3D%22mt-session-${environment}-${session?.coordinates.id.substring(
    0,
    8
  )}%22%0Alabels.k8s-pod%2Ftier%3D%22backend%22;?project=mirrortab-envs`;

  if (!id) throw Error("missing id");
  if (!namespace) throw Error("missing namespace");

  if (!session) return <CircularProgress/>;

  return (
    <>
      <Modal
        open={!!selectedEvent}
        onClose={() => setSelectedEvent(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle}>
          <Box>
            <DialogTitle style={{fontSize: 30, fontWeight: 3}}>
              View Event
            </DialogTitle>
            <DialogContent dividers>
              <Box marginTop={2}>
                <TextField
                  style={{width: "100%"}}
                  value={JSON.stringify(selectedEvent)}
                  multiline
                  rows={5}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setSelectedEvent(null)}
              >
                Close
              </Button>
            </DialogActions>
          </Box>
        </Card>
      </Modal>
      <Box marginTop={4}>
        <h1>Debug {id}</h1>
        <Box marginTop={4}>
          <Box>
            <Card style={{width: 600}}>
              <InfoTable
                rows={[
                  ["ID", <>{id}</>],
                  ["Namespace", <>{namespace}</>],
                  ["Created", <>{moment(session.created).fromNow()}</>],
                  ["Owner", session.owner ?? "(anonymous)"],
                  ["Expires At", moment(session.expires).format("lll")],
                  ["View Mode", session.viewMode],
                  ["URLs", session.urls],
                  session.slug ? ["Slug", <code>{session.slug}</code>] : null,
                  [
                    "Engine version",
                    <a
                      href={`https://gitlab.com/mirrortab/webrtc-desktop/-/commit/${session.webrtcDesktopVersion}`}
                    >
                      {session.webrtcDesktopVersion}
                    </a>,
                  ],
                  [
                    "Metrics",
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        window.open(
                          `https://console.cloud.google.com/kubernetes/service/us-central1/${environment}-mt-gke/default/mt-session-${environment}-${session.coordinates.id.substring(
                            0,
                            8
                          )}-service/overview?project=mirrortab-envs`
                        )
                      }
                    >
                      Metrics in GCP
                    </Button>,
                  ],
                  [
                    "Logs",
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => window.open(logsLink)}
                    >
                      Logs in GCP
                    </Button>,
                  ],
                  [
                    "Time to useful session",
                    logs?.timing?.requestToEngineReadyMs,
                  ],
                ]}
              />
            </Card>
          </Box>
        </Box>
        <Box marginTop={4}>
          <div style={{height: 800}}>
            <EventsTable
              setSelectedEvent={setSelectedEvent}
              events={logs?.events ?? []}
            />
          </div>
        </Box>

        <Box>
          <TextField
            size="small"
            value={`https://id.mirrortab.com/${id}`}
            onChange={() => {
            }}
            disabled
            style={{width: 500}}
          />
        </Box>
      </Box>
    </>
  );
};

type EventsTableProps = IEventsTableProps;

interface IEvent {
  occurred: number;
  id: string;
  data: string;
}

interface IEventsTableProps {
  events: IEvent[];
  setSelectedEvent: (event: string) => any;
}

export const EventsTable: React.FC<EventsTableProps> = (props) => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
    },
    {
      field: "occurred",
      headerName: "Occurred",
      renderCell: (params) =>
        params.value ? moment(params.value).fromNow() : "",
      width: 150,
    },
    {
      field: "data",
      headerName: "data",
      renderCell: (params) =>
        params.value ? JSON.stringify(params.value) : "",
      width: 750,
    },
    {
      field: "button",
      headerName: "",
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => props.setSelectedEvent(params.value)}
          >
            View
          </Button>
        ) : (
          ""
        ),
      sortable: false,
    },
  ];

  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{field: "occurred", sort: "desc"}],
          },
        }}
        columns={columns}
        rows={props.events.map((row) => ({...row, button: row.data}))}
      ></DataGrid>
    </>
  );
};

export const InfoTable: React.FC<{ rows: (any[] | null)[] }> = (props) => {
  return (
    <Table size="small" sx={{

    }}>
      {props.rows
        .filter((r) => !!r)
        .map((r: any, index: number) => (
          <TableRow
            sx={{
              maxHeight: 40,
              height: 40,
              "&:last-child": {
                borderBottom: "1px solid red"
              },
            }}
            key={r[0]}>
            <TableCell
              style={{
                fontWeight: 300,
                width: 200,
                maxWidth: 200,
                verticalAlign: "top",
              }}
            >
              {r[0]}
            </TableCell>
            <TableCell style={{width: 500, maxWidth: 500}}>{r[1]}</TableCell>
          </TableRow>
        ))}
    </Table>
  );
};
