import { LinearProgress } from "@mui/material";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AvatarDropdown } from "./AvatarDropdown";

export const HeaderAvatarButton: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const [, setAccessToken] = React.useState("");

  const loadToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      loadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <AvatarDropdown
      user={{
        name: user?.name || "Anonymous",
        email: user?.email || "Unknown",
      }}
      logout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
    />
  );
};
