import * as React from "react";
import * as WebRTCClientLib from "./webrtc";
import { MTDataChannelEvent } from "./types/Sesssion";
import { MTSpan, MTTracer } from "./lib/tracing/client";
import { useInputHandlers } from "./webrtc";
// types
export type KeyboardKey = {
  key: string;
  code: string;
};

export type KeyUpEvent = {
  type: "key_release";
  event: string;
};

export type KeyDownEvent = {
  type: "key_press";
  event: string;
};

export type RemoteEvent = KeyUpEvent | KeyDownEvent;

export type HandledKeyboardEvent = {
  newKeysDown: KeyboardKey[];
  sendImmediately: RemoteEvent[];
  preventDefault: boolean;
};

type KeyPressEvent = {
  type: "key_press";
  event: string;
};

type KeyReleaseEvent = {
  type: "key_release";
  event: string;
};

export const controlKeyForOs = (() => {
  if (navigator.platform.toLowerCase().indexOf('mac') !== -1) {
    return "Meta";
  } else {
    return "Control";
  }
})();

const shiftKey = "Shift";
const capsLock = "CapsLock";
const pasteShortcut = "v";

// event creation functions
const keyPressEvent = (key: string): KeyPressEvent => ({
  type: "key_press",
  event: key,
});

const keyReleaseEvent = (key: string): KeyReleaseEvent => ({
  type: "key_release",
  event: key,
});

// event handlers
export const keydownHandler = (
  e: KeyboardEvent,
  setKeysDown: React.Dispatch<React.SetStateAction<KeyboardKey[]>>,
  inputHandlers: any
) => {
  setKeysDown((existing) => {
    const handled = handleStatefulKeyDown(existing, {
      key: e.key,
      code: e.code,
    });

    if (handled.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    handled.sendImmediately.forEach((message) => {
      inputHandlers.send_traced(message as MTDataChannelEvent);
    });

    return [...new Set([...existing, ...handled.newKeysDown])];
  });
};

export const keyupHandler = (
  e: KeyboardEvent,
  setKeysDown: React.Dispatch<React.SetStateAction<KeyboardKey[]>>,
  inputHandlers: any
) => {
  switch (e.key) {
    case controlKeyForOs:
      inputHandlers.send_traced({
        type: "key_release",
        event: "ControlLeft",
      } as MTDataChannelEvent);
      setKeysDown((existing) =>
        existing.filter((item) => item.key !== "Control")
      );
      break;
    case shiftKey:
      inputHandlers.send_traced({
        type: "key_release",
        event: "ShiftLeft",
      } as MTDataChannelEvent);
      setKeysDown((existing) =>
        existing.filter((item) => item.key !== "Shift")
      );
      break;
    case capsLock:
      inputHandlers.send_traced({
        type: "key_press",
        event: "CapsLock",
      } as MTDataChannelEvent);
      inputHandlers.send_traced({
        type: "key_release",
        event: "CapsLock",
      } as MTDataChannelEvent);
      break;
    default:
      const span = inputHandlers.tracer?.startSpan("handleKeyRelease");
      inputHandlers.send_traced(
        { type: "key_release", event: e.code } as MTDataChannelEvent,
        span
      );

      setKeysDown((existing) =>
        existing.filter((item) => item.code !== e.code)
      );
      span?.end();
      break;
  }
};

const handleStatefulKeyDown = (
  existingDownKeys: KeyboardKey[],
  newKey: KeyboardKey
): HandledKeyboardEvent => {
  const CONTROL_CURRENTLY_HELD =
    existingDownKeys.map((k) => k.key).indexOf("Control") > -1;
  const SHIFT_CURRENTLY_HELD =
    existingDownKeys.map((k) => k.key).indexOf("Shift") > -1;

  switch (newKey.key) {
    case controlKeyForOs:
      return {
        newKeysDown: [{ key: "Control", code: "ControlLeft" }],
        sendImmediately: [],
        preventDefault: true,
      };
    // ignore Cmd+Shift+J and Ctrl+J to block downloads window and developer tools
    // (Cmd+Shift+J opent downloads on MacOS but Ctrl+Shift+J opens developer tools on Windows/Linux)
    case "j":
      return {
        newKeysDown: [],
        sendImmediately:
          CONTROL_CURRENTLY_HELD && SHIFT_CURRENTLY_HELD
            ? []
            : [
              ...(CONTROL_CURRENTLY_HELD
                ? [keyPressEvent(controlKeyForOs)]
                : []),
              ...(SHIFT_CURRENTLY_HELD ? [keyPressEvent("ShiftLeft")] : []),
              keyPressEvent(newKey.code),
              keyReleaseEvent(newKey.code),
            ],
        preventDefault: CONTROL_CURRENTLY_HELD,
      };
    // Use the browser's default behavior for focus address bar
    case "l":
      return {
        newKeysDown: CONTROL_CURRENTLY_HELD
          ? [{ key: "Control", code: "ControlLeft" }, newKey]
          : [newKey],
        sendImmediately: CONTROL_CURRENTLY_HELD
          ? []
          : [keyPressEvent(newKey.code), keyReleaseEvent(newKey.code)],
        preventDefault: false,
      };
    // ignore cmd+ctrl+N to prevent exiting kiosk mode
    case "n":
      return {
        newKeysDown: [],
        sendImmediately: CONTROL_CURRENTLY_HELD
          ? []
          : [
            ...(SHIFT_CURRENTLY_HELD ? [keyPressEvent("ShiftLeft")] : []),
            keyPressEvent(newKey.code),
            keyReleaseEvent(newKey.code),
          ],
        preventDefault: CONTROL_CURRENTLY_HELD,
      };
    // ignore Ctrl+P to prevent printing
    case "p":
      return {
        newKeysDown: [],
        sendImmediately: CONTROL_CURRENTLY_HELD
          ? []
          : [
            ...(SHIFT_CURRENTLY_HELD ? [keyPressEvent("ShiftLeft")] : []),
            keyPressEvent(newKey.code),
            keyReleaseEvent(newKey.code),
          ],
        preventDefault: CONTROL_CURRENTLY_HELD,
      };
    case shiftKey:
      return {
        newKeysDown: [{ key: "Shift", code: "ShiftLeft" }],
        sendImmediately: [],
        preventDefault: false,
      };
    case pasteShortcut:
      return {
        newKeysDown: [],
        sendImmediately: [
          ...(CONTROL_CURRENTLY_HELD
            ? []
            : [keyPressEvent(newKey.code), keyReleaseEvent(newKey.code)]),
        ],
        preventDefault: false,
      };
    case capsLock:
      return {
        newKeysDown: [],
        sendImmediately: [
          keyPressEvent("CapsLock"),
          keyReleaseEvent("CapsLock"),
        ],
        preventDefault: false,
      };
    default:
      return {
        newKeysDown: [],
        sendImmediately: [
          ...(CONTROL_CURRENTLY_HELD ? [keyPressEvent("ControlLeft")] : []),
          ...(SHIFT_CURRENTLY_HELD ? [keyPressEvent("ShiftLeft")] : []),
          keyPressEvent(newKey.code),
          keyReleaseEvent(newKey.code),
        ],
        preventDefault: true,
      };
  }
};
