import { Routes, Route, Navigate } from "react-router-dom";
import { Debug } from "../Debug";
import { SlugSession } from "../SlugSession";
import { Profile } from "../Profile";
import { DefaultLayout } from "../shared/DefaultLayout";
import { SessionDetail } from "../Session";
import { Sessions } from "../Sessions";
import { AuthRequired } from "../shared/AuthRequired";
import { Environments } from "../hub/Environments";
import { SessionWithSecret } from "../SessionWithSecret";
import { SiteWorkspaceCreate } from "../SiteWorkspaceCreate";
import { blueBackgroundColor, SiteContainer } from "../SiteContainer";
import { SiteDetail } from "../SiteDetail";
import { SiteCreate } from "../SiteCreate";
import { SiteWorkspaceDetail } from "../SiteWorkspaceDetail";

export const ConsoleRouter: React.FC = () => {
  const sitesLayoutProps = {
    backgroundColor: blueBackgroundColor,
  };

  return (
    <Routes>
      <Route path="" element={<Navigate to="/app/sessions" />} />
      <Route path=":slug" element={<SlugSession />} />
      <Route
        path="/test/:namespace/:id/:secret"
        element={<SessionWithSecret />}
      />
      <Route path="/app" element={<AuthRequired />}>
        <Route
          path="profile"
          element={
            <DefaultLayout>
              <Profile />
            </DefaultLayout>
          }
        />
        <Route
          path="debug"
          element={
            <DefaultLayout>
              <Debug />
            </DefaultLayout>
          }
        />
        <Route
          path="sessions"
          element={
            <DefaultLayout>
              <Sessions />
            </DefaultLayout>
          }
        ></Route>
        <Route
          path="session/:namespace/:id/debug"
          element={
            <DefaultLayout>
              <Debug />
            </DefaultLayout>
          }
        />
        <Route path="session/:namespace/:id" element={<SessionDetail />} />
        <Route
          path="environments"
          element={
            <DefaultLayout>
              <Environments />
            </DefaultLayout>
          }
        />
        <Route
          path="sites"
          element={
            <DefaultLayout {...sitesLayoutProps}>
              <SiteContainer />
            </DefaultLayout>
          }
        />
        <Route
          path="sites/create-workspace"
          element={
            <DefaultLayout {...sitesLayoutProps}>
              <SiteWorkspaceCreate />
            </DefaultLayout>
          }
        />
        <Route
          path="sites/workspaces/:workspaceId"
          element={
            <DefaultLayout {...sitesLayoutProps}>
              <SiteContainer>
                <SiteWorkspaceDetail />
              </SiteContainer>
            </DefaultLayout>
          }
        ></Route>
        <Route
          path="sites/workspaces/:workspaceId/create-site"
          element={
            <DefaultLayout {...sitesLayoutProps}>
              <SiteContainer>
                <SiteCreate />
              </SiteContainer>
            </DefaultLayout>
          }
        ></Route>
        <Route
          path="sites/workspaces/:workspaceId/site/:siteId"
          element={
            <DefaultLayout {...sitesLayoutProps}>
              <SiteContainer>
                <SiteDetail />
              </SiteContainer>
            </DefaultLayout>
          }
        ></Route>
      </Route>
    </Routes>
  );
};
