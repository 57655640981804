import * as React from "react";

import "./App.css";
import * as ReactDOM from "react-dom/client";
import { Router } from "./components/shared/Router";
import { MTDemoProviders } from "./components/Providers";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as tracing from "./lib/tracing";
import {
  AlwaysOffSampler,
  AlwaysOnSampler,
} from "@opentelemetry/sdk-trace-web";
import { B3InjectEncoding, B3Propagator } from "@opentelemetry/propagator-b3";
import { ConfigContextProvider, useConfigContext } from "./webappConfig";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ConfigDrawer } from "./components/ConfigDrawer";
import { MTTracerProvider } from "./lib/tracing/MTTracerProvider";
import {SiteWorkspaceContextProvider} from "./components/SiteWorkspaceContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

type TracingContext = {
  tracerProvider: MTTracerProvider | null;
  isTracingEnabled: boolean;
};

const TracingContext = React.createContext<TracingContext>({
  tracerProvider: null,
  isTracingEnabled: false,
});

export const useTracing = () => React.useContext(TracingContext);

export const TracingContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const {
    webAppConfig: { environment, tracingEnabled },
  } = useConfigContext();

  const isTracingEnabled = tracingEnabled();

  const [tracerProvider, setTracerProvider] =
    React.useState<MTTracerProvider | null>(null);

  React.useEffect(() => {
    const endpoint = `https://${environment()}.env.mirrortab.com/opentelemetry`;

    const setupTracing = (): MTTracerProvider | undefined => {
      if (isTracingEnabled) {
        return tracing.configureOpentelemetry({
          serviceName: "MirrorTab-Webapp",
          serviceVersion: "1.0.0",
          deploymentEnvironment: "local",
          sampler: isTracingEnabled
            ? new AlwaysOnSampler()
            : new AlwaysOffSampler(),
          endpoint: endpoint,
          // contextManager: new ZoneContextManager(),
          textMapPropagator: new B3Propagator({
            injectEncoding: B3InjectEncoding.MULTI_HEADER,
          }),
        });
      }
      return undefined;
    };

    setTracerProvider(setupTracing() ?? null);
  }, []);

  return (
    <TracingContext.Provider
      value={{
        isTracingEnabled,
        tracerProvider,
      }}
    >
      {props.children}
    </TracingContext.Provider>
  );
};

const App = () => {
  const { webAppConfig } = useConfigContext();

  React.useEffect(() => {
    const cspContent = `
      default-src 'self';
      script-src 'self' ${webAppConfig
        .cspDomains()
        .join(" ")};
      img-src 'self' * data:;
      frame-src 'self' https://login.mirrortab.com ${webAppConfig
        .cspDomains()
        .join(" ")};
      connect-src 'self' https://login.mirrortab.com http://localhost:8089 https://cdn.lr-intake.com ${webAppConfig
        .cspDomains()
        .join(" ")};
      style-src 'self' 'unsafe-inline' ${webAppConfig
        .cspDomains()
        .join(" ")};
    `;

    const metaTag = document.createElement("meta");
    metaTag.httpEquiv = "Content-Security-Policy";
    metaTag.content = cspContent.trim();

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, [webAppConfig]);

  return (
    <>
      <Router />
    </>
  );
};

root.render(
  <ConfigContextProvider>
    <ConfigDrawer />
    <TracingContextProvider>
      <ErrorBoundary>
        <MTDemoProviders>
          <SiteWorkspaceContextProvider>
            <App />
          </SiteWorkspaceContextProvider>
        </MTDemoProviders>
      </ErrorBoundary>
    </TracingContextProvider>
  </ConfigContextProvider>
);

reportWebVitals();
