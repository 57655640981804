// import { EViewMode } from "@mirrortab/webrtc-client-player";
import {
  BugReportOutlined,
  CopyAllOutlined,
  RestoreOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Modal,
  Slide,
  SpeedDial,
  SpeedDialAction,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useGeoip } from "../hooks/geoip";
import { useCreateSession } from "../hooks/session";
import { useSlug } from "../hooks/slug";
import { useTroubleshoot } from "../hooks/troubleshoot";
import { InfoTable } from "./Debug";
import { SessionViewerWithWait } from "./Session";
import MirrorTabLogo from "../mt_logo_512x512.png";
import { FullScreenError } from "./FullScreenError";
import { EViewMode } from "../WebRTCClient";
import {
  removeExistingCoordinatesForSlug,
  useCoordinatesForSlug,
} from "../lib/localstorage";

export const useResetBasedOnHashBehavior = (slug: string | undefined) => {
  React.useEffect(() => {
    if (!slug) return;

    if (window.location.hash.indexOf("reset") > -1) {
      removeExistingCoordinatesForSlug(slug);
    }
  }, [slug]);
};

interface IMirrorTabBugProps {
  slug: string | null;
  sessionId: string | null;
}
type MirrorTabBugProps = IMirrorTabBugProps;

export const MirrorTabBug: React.FC<MirrorTabBugProps> = (props) => {
  /* 
Bug as in : 
https://en.wikipedia.org/wiki/Digital_on-screen_graphic

not as in:
https://education.nationalgeographic.org/resource/worlds-first-computer-bug/
*/

  const defaultValue = false;
  const [sessionId, setSessionId] = React.useState("");
  const [namespace, setNamespace] = React.useState("");
  const [open, setOpen] = React.useState(defaultValue);
  const [, setBannerShown] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [speedDialShown, setSpeedDialShown] = React.useState(true);
  const [submittingFeedback, setSubmittingFeedback] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const [feedbackEmail, setFeedbackEmail] = React.useState("");
  const { submit } = useTroubleshoot();

  const handleOpen = () => {
    setOpen(true);
    setBannerShown(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBannerShown(false);
  };

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    minWidth: 800,
    minHeight: 500,
    padding: 2,
  };

  const onSubmit = async () => {
    setSubmittingFeedback(true);
    await submit(sessionId, namespace, feedbackEmail, feedback);
    setSubmittingFeedback(false);
    setFeedback("");
    setFeedbackEmail("");
    window.location.reload();
  };

  const copySessionId = () => {
    navigator.clipboard.writeText(props.sessionId ?? "");
  };

  const feedbackButton = (
    <SpeedDialAction
      key={"bug report"}
      icon={<BugReportOutlined />}
      tooltipTitle={"Bug report"}
      tooltipOpen
      onClick={() => setFeedbackOpen(true)}
    />
  );

  const submitDisabled = !feedbackEmail || !feedback;

  return (
    <>
      <Backdrop open={open} />
      <Slide in={speedDialShown} direction="up">
        <SpeedDial
          ariaLabel="tooltip"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={
            <img
              style={{
                background: "white",
                borderRadius: "50%",
                height: "100%",
              }}
              src={MirrorTabLogo}
              alt="MirrorTab logo"
            />
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {false && feedbackButton}
          <SpeedDialAction
            key={"copyId"}
            icon={<CopyAllOutlined />}
            tooltipTitle={"Copy ID"}
            tooltipOpen
            onClick={() => copySessionId()}
          />
          {props.slug && (
            <SpeedDialAction
              key={"reset"}
              icon={<RestoreOutlined />}
              tooltipTitle={"Reset"}
              tooltipOpen
              onClick={() =>
                removeExistingCoordinatesForSlug(props.slug as string)
              }
            />
          )}
        </SpeedDial>
      </Slide>
      <Modal
        open={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <DialogTitle style={{ fontSize: 30, fontWeight: 3 }}>
            Submit Feedback
          </DialogTitle>
          <DialogContent>
            <Typography>
              Please tell us what went wrong during your session.
            </Typography>
            <Box marginTop={2}>
              <InfoTable
                rows={[
                  ["ID", <>{sessionId}</>],
                  ["Namespace", <>{namespace}</>],
                  ["Slug", <code>{props.slug}</code>],
                  [
                    "Email address",
                    <TextField
                      size="small"
                      style={{ width: "100%" }}
                      placeholder="Email address"
                      value={feedbackEmail}
                      onChange={(e) => setFeedbackEmail(e.target.value)}
                    />,
                  ],
                  [
                    "Feedback",
                    <TextField
                      rows={5}
                      style={{ width: "100%" }}
                      placeholder="Feedback, bug report, or general comments"
                      multiline
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />,
                  ],
                ]}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submittingFeedback || submitDisabled}
              variant={"contained"}
              autoFocus
              onClick={onSubmit}
            >
              {submittingFeedback ? <CircularProgress /> : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );
};

type SlugSessionProps = ISlugSessionProps;

interface ISlugSessionProps {
  slug: string;
}

export const SlugSession: React.FC = (props) => {
  const { slug } = useParams<{ slug: string }>();
  if (!slug) throw Error("sno slug");

  return <SlugSessionWithExplicitSlug slug={slug}/>
}

export const SlugSessionWithExplicitSlug: React.FC<SlugSessionProps> = (props) => {
  const { getGeoIp } = useGeoip();
  const [, setCreating] = React.useState(false);
  const { create } = useCreateSession();
  const { slug } = props;
  const [sessionId, setSessionId] = React.useState("");
  const [namespace, setNamespace] = React.useState("");
  const slugCoordinates = useCoordinatesForSlug(slug ?? "");

  const [invalidSlug, setInvalidSlug] = React.useState(false);
  useResetBasedOnHashBehavior(slug);

  if (!slug) throw Error("no slug");

  const createNewSession = async (url: string) => {
    setCreating(true);
    const geoip = await getGeoIp();
    const session = await create(
      [url],
      "shared",
      EViewMode.Kiosk,
      null,
      slug,
      JSON.stringify(geoip),
      1,
      String(60 * 60),
      "aurora4"
    );
    setCreating(false);
    if (!session.session) throw Error();

    return session.session;
  };

  const { get: getSlug } = useSlug();

  const load = async () => {
    if (!slugCoordinates) {
      const slugInfo = await getSlug(slug);
      if (!slugInfo.url) {
        setInvalidSlug(true);
        return;
      }
      const newSession = await createNewSession(slugInfo.url);
      localStorage.setItem(`${slug}`, JSON.stringify(newSession.coordinates));
      setSessionId(newSession.coordinates.id);
      setNamespace(newSession.coordinates.namespace);
      return;
    } else {
      setSessionId(slugCoordinates.id);
      setNamespace(slugCoordinates.namespace);
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  if (invalidSlug) {
    return (
      <FullScreenError
        id="invalidSlugError"
        message="The slug you entered is not valid."
      ></FullScreenError>
    );
  }

  if (!sessionId || !namespace) return <LinearProgress />;

  return (
    <>
      <MirrorTabBug slug={slug} sessionId={sessionId} />
      <SessionViewerWithWait id={sessionId} namespace={namespace} slug={slug} />
    </>
  );
};
