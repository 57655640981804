import { Box, Container } from "@mui/material";
import * as React from "react";
import { Header } from "./Header";
import { blueBackgroundColor } from "../SiteContainer";

type DefaultLayoutOwnProps = {
  backgroundColor?: string | null;
};

type DefaultLayoutProps = DefaultLayoutOwnProps & {
  children: React.ReactElement;
};

export const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  return (
    <>
      <Header />
      <Box
        style={{
          background: props.backgroundColor ?? "white",
          height: "100vh",
        }}
      >
        <Container maxWidth="xl">{props.children}</Container>
      </Box>
    </>
  );
};
