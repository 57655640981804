import api, {Tracer, Span, Attributes, SpanStatusCode, SpanKind} from "@opentelemetry/api";
import {SpanContext} from "@opentelemetry/api/build/src/trace/span_context";
import {SpanAttributes, SpanAttributeValue} from "@opentelemetry/api/build/src/trace/attributes";
import {TimeInput} from "@opentelemetry/api/build/src/common/Time";
import {SpanStatus} from "@opentelemetry/api/build/src/trace/status";
import {MTDataChannelTracingInfo} from "../../types/Sesssion";
import {SpanOptions} from "@opentelemetry/api/build/src/trace/SpanOptions";

const DUMMY_SPAN_NAME = '__dummy__'
const DUMMY_TRACER_NAME = '__dummy__'

export class MTTracer {
    private _tracer: Tracer
    private _name: string
    private _sessionId?: string

    constructor(tracer: Tracer, name: string, sessionId?: string) {
        this._tracer = tracer;
        this._name = name;
        this._sessionId = sessionId;
    }

    private startChildSpan(name: string, parent: Span, options?: SpanOptions): MTSpan | undefined {
        const ctx = api.trace.setSpan(api.context.active(), parent);
        const span = this._tracer?.startSpan(name, options, ctx);
        if (span !== undefined) {
            return new MTSpan(span, this, parent)
        } else {
            return undefined;
        }
    }

    private startActiveSpan<R>(name: string, callback: (span: MTSpan | undefined) => R): any {
        return this._tracer.startActiveSpan(name,
            (span) => {
                let mtSpan: MTSpan | undefined = undefined
                if (span !== undefined) {
                    mtSpan = new MTSpan(span, this)
                }
                try {
                    const response = callback(mtSpan);
                    span.setStatus({code: SpanStatusCode.OK});
                    return response;
                } catch (error: any) {
                    span.recordException(error);
                    span.setStatus({code: SpanStatusCode.ERROR});
                    throw error;
                } finally {
                    span.end();
                }
            });
    }

    public async startSpanForInstrumented<R>(name: string, callback: () => Promise<R>, parent?: Span | MTSpan,): Promise<R | undefined> {
        const parentSpan = this.startSpan(name, parent)
        if (parentSpan === undefined) {
            return undefined
        }


        api.trace.setSpan(api.context.active(), parentSpan.getInternalSpan());
            // surround auto instrumentated code with the new context created in the function
        return this._tracer.startActiveSpan('wrapper_span', { kind: SpanKind.CLIENT }, async (wrapper) => {

            console.log(JSON.stringify(api.context.active(), null, 2))
            try {
                return await callback();
            } catch (err: any) {
                wrapper.recordException(err.message);
                wrapper.setStatus({
                    code: SpanStatusCode.ERROR
                });
            } finally {
                wrapper.end();
            }
        });
    }


    private startRootSpan(name: string, options?: SpanOptions): MTSpan | undefined {
        const span = this._tracer?.startSpan(name, options);
        if (span !== undefined) {
            return new MTSpan(span, this)
        } else {
            return undefined;
        }
    }

    public startSpan(name: string, parent?: Span | MTSpan, options?: SpanOptions): MTSpan | undefined {
        let span: MTSpan | undefined;
        if (parent !== undefined) {
            if (parent instanceof MTSpan) {
                span = this.startChildSpan(name, parent.getInternalSpan(), options);
            } else {
                span = this.startChildSpan(name, parent, options);
            }

        } else {
            span = this.startRootSpan(name);
        }

        if (span !== undefined && this._sessionId !== undefined) {
            span.getInternalSpan().setAttribute("mt.session.id", this._sessionId)
        }

        return span;
    }

    public getInternalTracer(): Tracer | undefined {
        return this._tracer
    }

    public setSessionId(id: string) {
        this._sessionId = id;
    }

    public name(): string {
        return this._name
    }
}

export class MTSpan {
    private _tracer?: MTTracer
    private _parent?: Span
    private _current: Span

    constructor(current: Span, tracer?: MTTracer, parent?: Span) {
        this._tracer = tracer;
        this._current = current;
        this._parent = parent;
    }

    public spanContext(): SpanContext {
        return this._current.spanContext();
    }

    public setAttribute(key: string, value: SpanAttributeValue): Span{
        return this._current.setAttribute(key, value);
    }

    public setAttributes(attributes: SpanAttributes): Span {
        return this._current.setAttributes(attributes);
    }

    public addEvent(name: string, attributesOrStartTime?: SpanAttributes | TimeInput, startTime?: TimeInput): Span {
        return this._current.addEvent(name, attributesOrStartTime, startTime);
    }

    public setStatus(status: SpanStatus): Span {
        return this._current.setStatus(status);
    }

    public updateName(name: string): Span {
        return this._current.updateName(name);
    }

    public end(endTime?: TimeInput): void {
        return this._current.end(endTime);
    }

    public isRecording(): boolean {
        return this._current.isRecording();
    }

    // reportException reports an exception as a span event creating a dummy span if necessary
    public recordException(err: Error | string, attrs: Attributes = {}): void {
        reportExceptionInternal(err, attrs, this._tracer, this._current);
    }

    public getInternalSpan(): Span {
        return this._current;
    }

    public getMTDataChannelTraceInfo(): MTDataChannelTracingInfo {
        return {
            span_id: this._current.spanContext().spanId,
            trace_id: this._current.spanContext().traceId,
            sample: "1"
        }
    }
}


export function reportExceptionInternal(err: Error | string, attrs: Attributes = {}, existingTracer?: MTTracer | Tracer, current?: Span) {
    let startedSpan = false

    let tracer = existingTracer;
    if (tracer === undefined) {
        tracer = api.trace.getTracer(DUMMY_TRACER_NAME)
    }

    let span: MTSpan | Span | undefined = current;
    if (span === undefined) {
        span = tracer.startSpan(DUMMY_SPAN_NAME)
        startedSpan = true
    }

    if (typeof err === 'string') {
        attrs['exception.message'] = err
        span?.addEvent('exception', attrs);
    } else {
        span?.recordException(err)
    }

    // If we started a dummmy span we need to close it
    if (startedSpan) {
        span?.end()
    }
}