import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  GetSiteWorkspaceQuery,
  GetSiteWorkspacesQuery,
} from "../__generated__/graphql";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const LOCAL_STORAGE_KEY = "selectedWorkspaceId";

type SiteWorkspaceContext = {
  loading?: boolean;
  siteWorkspaceData?: GetSiteWorkspaceQuery | null;
  workspaceId?: string | null;
  setSelectedWorkspace?: (id: string) => void;
  refetchWorkspaces?: () => any;
  refetchWorkspace?: () => any;
  workspaceListLoading?: boolean;
  workspaceListData?: GetSiteWorkspacesQuery | null;
};

const defaultSiteWorkspaceContext: SiteWorkspaceContext = {};

const GET_SITE_WORKSPACE = gql(`
  query GetSiteWorkspace($id: String!) {
    siteWorkspace(id: $id) {
      id
      title
      userAssociations {
        id
        created
        user {
          id
          emailAddress
          firstName
          lastName
        }
      }
      sites {
        id
        stats {
          hits
        }
        slug
        type
        title
        description
        domains {
          id
          domainName
        }
        ... on HostedSite {
          currentLiveDeployment {
            id
            bundle {
              id
              hash
            }
          }
        }
        ... on ProxySite {
          url
        }
      }
    }
  }
`);

export const GET_SITE_WORKSPACES_QUERY = gql(`
  query GetSiteWorkspaces {
    viewer {
      user {
        firstName
        lastName
        emailAddress
        id
        siteWorkspaceUserAssociations {
          id
          created
          siteWorkspace {
            id
            title
          }
        }
      }
    }
  }
`);

const SiteWorkspaceContext = React.createContext(defaultSiteWorkspaceContext);

export const SiteWorkspaceContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const navigate = useNavigate();
  const { workspaceId: workspaceIdFromUrl } = useParams();
  const [workspaceId, setWorkspaceId] = useState<string | null>(null);
  const location = useLocation();

  const {
    data: workspaceData,
    refetch: refetchWorkspace,
    loading: workspaceLoading,
  } = useQuery(GET_SITE_WORKSPACE, {
    variables: { id: workspaceId ?? "" },
    skip: !workspaceId,
  });

  const {
    data: workspaceListData,
    loading: workspaceListLoading,
    refetch: refetchWorkspaces,
  } = useQuery(GET_SITE_WORKSPACES_QUERY, { fetchPolicy: "no-cache" });

  const setSelectedWorkspace = (id: string) => {
    navigate(`/app/sites/workspaces/${id}`);
    setWorkspaceId(id);
  };

  const navigateToWorkspace = (id: string) => {
    if (
      location.pathname === "/app/sites" ||
      location.pathname === "/app/sites/"
    ) {
      navigate(`/app/sites/workspaces/${id}`);
      setWorkspaceId(id);
    }
  };

  useEffect(() => {
    if (workspaceIdFromUrl) {
      setWorkspaceId(workspaceIdFromUrl);
    }
  }, [workspaceIdFromUrl]);

  useEffect(() => {
    if (workspaceId) {
      localStorage.setItem(LOCAL_STORAGE_KEY, workspaceId);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceLoading && workspaceListData) {
      const storedWorkspaceId = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (!workspaceIdFromUrl && storedWorkspaceId) {
        navigateToWorkspace(storedWorkspaceId);
      } else if (!workspaceIdFromUrl && !storedWorkspaceId) {
        const firstWorkspace =
          workspaceListData.viewer.user.siteWorkspaceUserAssociations[0];
        if (firstWorkspace) {
          navigateToWorkspace(firstWorkspace.siteWorkspace.id);
        }
      } else if (workspaceIdFromUrl) {
        setWorkspaceId(workspaceIdFromUrl);
      }
    }
  }, [
    workspaceIdFromUrl,
    workspaceListData,
    workspaceLoading,
    workspaceListLoading,
  ]);

  const siteWorkspaceContextValue: SiteWorkspaceContext = {
    siteWorkspaceData: workspaceData,
    loading: workspaceLoading,
    workspaceId,
    setSelectedWorkspace,
    refetchWorkspaces,
    refetchWorkspace,
    workspaceListLoading,
    workspaceListData,
  };

  return (
    <SiteWorkspaceContext.Provider value={siteWorkspaceContextValue}>
      {props.children}
    </SiteWorkspaceContext.Provider>
  );
};

export const useSiteWorkspaceContext = () =>
  React.useContext(SiteWorkspaceContext);
