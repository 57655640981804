import { useAuth0 } from "@auth0/auth0-react";
import { useConfigContext, useDebugLogger } from "../webappConfig";
import { useTracing } from "..";
import { mtFetch } from "../lib/utils";
import { MTSpan } from "../lib/tracing/client";

export enum AuthorizationStatus {
  Allow = "Allow",
  Deny = "Deny",
}

export const useEnvironmentAuthCheck = () => {
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("WebAppSessions");
  const { debugLog } = useDebugLogger();
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();

  const { getAccessTokenSilently } = useAuth0();

  const check = async (parentSpan?: MTSpan) => {
    const span = tracer?.startSpan("handshake", parentSpan);

    const data = await mtFetch(
      `${API_BASE_URL}/viewer`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
      tracer,
      span
    );

    if (data.status !== 200) return AuthorizationStatus.Deny;

    return AuthorizationStatus.Allow;
  };

  return { check };
};
