import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useReconnectingWebsocket, useWebsocketReady } from "../hooks/session";
import { FullScreenError } from "./FullScreenError";
import { HISTORY } from "./Providers";
import { EViewMode, WebRTCClient } from "../WebRTCClient";
import { MTSpan, MTTracer } from "../lib/tracing/client";
import { useConfigContext } from "../webappConfig";
import { useTracing } from "../index";
import tracing from "../lib/tracing";

export const SessionDetail: React.FC = (props) => {
  const { id, namespace } = useParams<{ id: string; namespace: string }>();

  if (!id) throw Error("missing id");
  if (!namespace) throw Error("missing namespace");

  return (
    <Box>
      <SessionViewerWithWait {...{ id, namespace }} />
    </Box>
  );
};

export const SessionViewerWithWait: React.FC<{
  slug?: string | null;
  id: string;
  namespace: string;
}> = (props) => {
  const [sessionReady, setSessionReady] = React.useState(false);
  const { id, namespace } = props;
  const [secret, setSecret] = React.useState("");

  if (!id) throw Error("missing id");
  if (!namespace) throw Error("missing namespace");

  const { poll } = useWebsocketReady(() => setSessionReady(true));

  const { connect } = useReconnectingWebsocket({ id, namespace }, (message) => {
    switch (message.type) {
      case "SessionExists":
        if (message.session.deleted && props.slug) {
          localStorage.removeItem(props.slug);
          window.location.reload();
        }
        setSecret(message.session.secret);
        break;
      case "SessionReady":
        break;
      default:
        break;
    }
  });

  const { tracerProvider, isTracingEnabled } = useTracing();
  tracerProvider?.setSessionId(id);
  const tracer = tracerProvider?.getMTTracer("WebRTCClient", id);

  const span = tracer?.startSpan("CreatingSession");

  React.useEffect(() => {
    connect(span);
    if (secret) {
      poll(secret);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secret]);

  if (sessionReady) {
    return (
      <SessionViewer
        {...{ id, namespace }}
        {...props}
        secret={secret}
        tracer={tracer}
        parentSpan={span}
      />
    );
  } else {
    return <LinearProgress />;
  }
};

export const SessionViewer: React.FC<{
  secret: string;
  id: string | null;
  namespace: string | null;
  tracer?: MTTracer;
  parentSpan?: MTSpan;
}> = (props) => {
  const { webAppConfig } = useConfigContext();
  const sessionWebsocketBaseUrl = webAppConfig.sessionWebsocketBaseUrl();
  const { id: routeId, namespace: routeNamespace } = useParams<{
    id: string;
    namespace: string;
  }>();
  const { id: propsId, namespace: propsNamespace, tracer, parentSpan } = props;
  const { secret } = props;

  const id = routeId ?? propsId;
  const namespace = routeNamespace ?? propsNamespace;

  if (!id) throw Error("missing id");
  if (!namespace) throw Error("missing namespace");

  const [status, setStatus] = React.useState<"closed" | "open" | "superseded">(
    "open"
  );

  const webrtcClient = React.useMemo(() => {
    const span = tracer?.startSpan("Setting up WebRTCClient", parentSpan);
    if (status === "closed") {
      span?.end();
      return (
        <FullScreenError
          id="connectionClosedError"
          message={`Connection closed.`}
        ></FullScreenError>
      );
    } else if (status === "superseded") {
      span?.end();
      return (
        <FullScreenError
          id="supersededError"
          message={`This session was accessed in another tab. Please use that tab or refresh this page to access the session.`}
        ></FullScreenError>
      );
    }
    if (!id || !namespace) {
      throw Error();
    }

    return (
      <>
        <WebRTCClient
          key={id}
          id={id}
          namespace={namespace}
          viewMode={EViewMode.Tabbed}
          onInteractionEvent={() => {}}
          onStatusChanged={setStatus}
          tracer={tracer}
          parentSpan={span}
          serverUrl={`${sessionWebsocketBaseUrl}/${secret}/ws`}
          history={HISTORY}
        />
      </>
    );
  }, [id, namespace, secret, status]);

  parentSpan?.end();
  return webrtcClient;
};
