import {MTSpan, MTTracer} from "./tracing/client";
import api, {HrTime, Span, SpanKind} from "@opentelemetry/api";
import * as web from '@opentelemetry/sdk-trace-web';
import * as core from '@opentelemetry/core';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import {AttributeNames} from "@opentelemetry/instrumentation-fetch/build/esnext/enums/AttributeNames";
import {FetchError, FetchResponse, SpanData} from "@opentelemetry/instrumentation-fetch/build/esnext/types";
import {safeExecuteInTheMiddle} from "@opentelemetry/instrumentation";

// how long to wait for observer to collect information about resources
// this is needed as event "load" is called before observer
// hard to say how long it should really wait, seems like 300ms is
// safe enough
const OBSERVER_WAIT_TIME_MS = 300;

const propagateTraceHeaderCorsUrls = [
    new RegExp("http://localhost:8089.*"),
    new RegExp("http://localhost:3000.*"),
    new RegExp("https://sandbox.env.mirrortab.com/api.*"),
    new RegExp("http://sandbox.env.mirrortab.com/api.*"),
    new RegExp("http://.*.env.mirrortab.com/api.*"),
    new RegExp("https://.*.env.mirrortab.com/api.*"),
    // new RegExp(".*")
]

const _removeProtocolAndDomain = (url: string): string => {
    // Create a new URL object
    const parsedUrl = new URL(url);

    // Get the pathname and search parameters
    const { pathname, search } = parsedUrl;

    // Concatenate pathname and search to form the remaining part of the URL
    return pathname + search;
}

/**
 * Creates a new span
 * @param url
 * @param options
 * @param tracer
 * @param parentSpan
 */
const _createSpan = (url: string, options: Partial<Request | RequestInit> = {}, tracer?: MTTracer, parentSpan?: Span | MTSpan): MTSpan | undefined => {
    // if (core.isUrlIgnored(url, _getConfig().ignoreUrls)) {
    //     this._diag.debug('ignoring span as url matches ignored url');
    //     return;
    // }
    const method = (options.method || 'GET').toUpperCase();
    const spanName = _removeProtocolAndDomain(url);
    return tracer?.startSpan(spanName, parentSpan, {
        kind: SpanKind.CLIENT,
        attributes: {
            [AttributeNames.COMPONENT]: 'fetch',
            [SemanticAttributes.HTTP_METHOD]: method,
            [SemanticAttributes.HTTP_URL]: url,
        },
    });
}

/**
 * Prepares a span data - needed later for matching appropriate network
 *     resources
 * @param spanUrl
 */
const _prepareSpanData = (spanUrl: string): SpanData => {
    const startTime = core.hrTime();
    const entries: PerformanceResourceTiming[] = [];
    if (typeof PerformanceObserver !== 'function') {
        return { entries, startTime, spanUrl };
    }

    const observer = new PerformanceObserver(list => {
        const perfObsEntries = list.getEntries() as PerformanceResourceTiming[];
        perfObsEntries.forEach(entry => {
            if (entry.initiatorType === 'fetch' && entry.name === spanUrl) {
                entries.push(entry);
            }
        });
    });
    observer.observe({
        entryTypes: ['resource'],
    });
    return { entries, observer, startTime, spanUrl };
}

const _applyAttributesAfterFetch = (span: MTSpan, request: Request | RequestInit, result: Response | FetchError) => {
    // const applyCustomAttributesOnSpan =
    //     this._getConfig().applyCustomAttributesOnSpan;
    // if (applyCustomAttributesOnSpan) {
    //     safeExecuteInTheMiddle(
    //         () => applyCustomAttributesOnSpan(span, request, result),
    //         error => {
    //             if (!error) {
    //                 return;
    //             }
    //
    //         },
    //         true
    //     );
    // }
}

/**
 * Finish span, add attributes, network events etc.
 * @param span
 * @param spanData
 * @param response
 */
const _endSpan = (span: MTSpan, spanData: SpanData, response: FetchResponse) => {
    const endTime = core.millisToHrTime(Date.now());
    const performanceEndTime = core.hrTime();
    _addFinalSpanAttributes(span, response);

    setTimeout(() => {
        spanData.observer?.disconnect();
        span.end(endTime);
    }, OBSERVER_WAIT_TIME_MS);
}

/**
 * Adds more attributes to span just before ending it
 * @param span
 * @param response
 */
const _addFinalSpanAttributes = (span: MTSpan, response: FetchResponse): void  => {
    const parsedUrl = web.parseUrl(response.url);
    span.setAttribute(SemanticAttributes.HTTP_STATUS_CODE, response.status);
    if (response.statusText != null) {
        span.setAttribute(AttributeNames.HTTP_STATUS_TEXT, response.statusText);
    }
    span.setAttribute(SemanticAttributes.HTTP_HOST, parsedUrl.host);
    span.setAttribute(
        SemanticAttributes.HTTP_SCHEME,
        parsedUrl.protocol.replace(':', '')
    );
    if (typeof navigator !== 'undefined') {
        span.setAttribute(
            SemanticAttributes.HTTP_USER_AGENT,
            navigator.userAgent
        );
    }
}

/**
 * Add headers
 * @param options
 * @param spanUrl
 */
const _addHeaders = (options: Request | RequestInit, spanUrl: string): void => {
    if (!web.shouldPropagateTraceHeaders(spanUrl, propagateTraceHeaderCorsUrls)) {
        const headers: Partial<Record<string, unknown>> = {};
        api.propagation.inject(api.context.active(), headers);
        if (Object.keys(headers).length > 0) {
            console.warn('headers inject skipped due to CORS policy');
        }
        return;
    }

    if (options instanceof Request) {
        api.propagation.inject(api.context.active(), options.headers, {
            set: (h, k, v) => h.set(k, typeof v === 'string' ? v : String(v)),
        });
    } else if (options.headers instanceof Headers) {
        api.propagation.inject(api.context.active(), options.headers, {
            set: (h, k, v) => h.set(k, typeof v === 'string' ? v : String(v)),
        });
    } else if (options.headers instanceof Map) {
        api.propagation.inject(api.context.active(), options.headers, {
            set: (h, k, v) => h.set(k, typeof v === 'string' ? v : String(v)),
        });
    } else {
        const headers: Partial<Record<string, unknown>> = {};
        api.propagation.inject(api.context.active(), headers);
        options.headers = Object.assign({}, headers, options.headers || {});
    }
}

export const mtFetch = (input: RequestInfo | URL, init?: RequestInit, tracer?: MTTracer, parentSpan?: Span | MTSpan): Promise<Response> => {
    const url = web.parseUrl(input instanceof Request ? input.url : String(input)).href;

    const options = input instanceof Request ? input : init || {};
    const createdSpan = _createSpan(url, options, tracer, parentSpan);
    if (!createdSpan) {
        return fetch(input, init);
    }
    const spanData = _prepareSpanData(url);

    function endSpanOnError(span: MTSpan, error: FetchError) {
        _applyAttributesAfterFetch(span, options, error);
        _endSpan(span, spanData, {
            status: error.status || 0,
            statusText: error.message,
            url,
        });
    }

    function endSpanOnSuccess(span: MTSpan, response: Response) {
        _applyAttributesAfterFetch(span, options, response);
        if (response.status >= 200 && response.status < 400) {
            _endSpan(span, spanData, response);
        } else {
            _endSpan(span, spanData, {
                status: response.status,
                statusText: response.statusText,
                url,
            });
        }
    }

    function onSuccess(
        span: MTSpan,
        resolve: (value: Response | PromiseLike<Response>) => void,
        response: Response
    ): void {
        try {
            const resClone = response.clone();
            const resClone4Hook = response.clone();
            const body = resClone.body;
            if (body) {
                const reader = body.getReader();
                const read = (): void => {
                    reader.read().then(
                        ({ done }) => {
                            if (done) {
                                endSpanOnSuccess(span, resClone4Hook);
                            } else {
                                read();
                            }
                        },
                        error => {
                            endSpanOnError(span, error);
                        }
                    );
                };
                read();
            } else {
                // some older browsers don't have .body implemented
                endSpanOnSuccess(span, response);
            }
        } finally {
            resolve(response);
        }
    }

    function onError(
        span: MTSpan,
        reject: (reason?: unknown) => void,
        error: FetchError
    ) {
        try {
            endSpanOnError(span, error);
        } finally {
            reject(error);
        }
    }

    return new Promise((resolve, reject) => {
        return api.context.with(
            api.trace.setSpan(api.context.active(), createdSpan.getInternalSpan()),
            () => {
                _addHeaders(options, url);
                // TypeScript complains about arrow function captured a this typed as globalThis
                // ts(7041)
                let fetchPromise
                if (options instanceof Request) {
                    fetchPromise = fetch(options)
                } else {
                    fetchPromise = fetch(url, options)
                }
                return fetchPromise
                    .then(response => {
                        onSuccess(createdSpan, resolve, response)
                    })
                    .catch(err => {
                        onError(createdSpan, reject, err)
                    })
            }
        );
    });
}

