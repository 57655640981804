import { Attributes, ContextManager, TextMapPropagator } from '@opentelemetry/api'
import { TracerConfig } from '@opentelemetry/sdk-trace-base'
import {
    BatchSpanProcessor,
    ConsoleSpanExporter,
    SimpleSpanProcessor,
    WebTracerProvider
} from '@opentelemetry/sdk-trace-web'
import  api from "@opentelemetry/api"
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations, InstrumentationOption } from '@opentelemetry/instrumentation'

import {MTTracer, reportExceptionInternal} from './client'
import { createResource, Config as BaseConfig } from './config'
import {MTTracerProvider} from "./MTTracerProvider";
import {FetchInstrumentation} from "@opentelemetry/instrumentation-fetch";
import {UserInteractionInstrumentation} from "@opentelemetry/instrumentation-user-interaction";

const hasWindow = typeof window !== 'undefined'

let _DEFAULT_TRACER: MTTracer | undefined;

export function reportException(err: Error | string, attrs: Attributes = {}) {
    reportExceptionInternal(err, attrs, _DEFAULT_TRACER?.getInternalTracer())
}

export interface Config extends BaseConfig, TracerConfig {
    endpoint: string;
    contextManager?: ContextManager
    textMapPropagator?: TextMapPropagator
}

export function configureOpentelemetry(conf: Config) : MTTracerProvider | undefined {
    configureResource(conf)
    let output = configureTracing(conf)

    if (hasWindow) {
        setupOnError()
    }

    return output;
}

function configureResource(conf: Config) {
    conf.resource = createResource(
        conf.resource,
        conf.resourceAttributes,
        conf.serviceName ?? '',
        conf.serviceVersion ?? '',
        conf.deploymentEnvironment ?? '',
    )
}

function configureTracing(conf: Config) : MTTracerProvider {

    // const consoleExporter = new ConsoleSpanExporter();
    const collectorExporter = new OTLPTraceExporter({
        headers: {},
        url: `${conf.endpoint}/v1/traces`
    })

    // const simpleConsoleProcessor = new SimpleSpanProcessor(consoleExporter)
    // const simpleCollectorProcessor = new SimpleSpanProcessor(collectorExporter)
    const spanProcessor = new BatchSpanProcessor(collectorExporter, {
        maxExportBatchSize: 1000,
        maxQueueSize: 1000,
        scheduledDelayMillis: 5 * 1000,
    })

    const provider = new WebTracerProvider({
        sampler: conf.sampler,
        spanLimits: conf.spanLimits,
        resource: conf.resource,
        idGenerator: conf.idGenerator,
        forceFlushTimeoutMillis: conf.forceFlushTimeoutMillis,
    })

    provider.addSpanProcessor(spanProcessor)
    // provider.addSpanProcessor(simpleConsoleProcessor)
    // provider.addSpanProcessor(simpleCollectorProcessor)

    const tracerProvider = new MTTracerProvider(provider);

    tracerProvider.register({
        contextManager: conf.contextManager,
        propagator: conf.textMapPropagator,
    })

    _DEFAULT_TRACER = tracerProvider.getMTTracer('mt-webrtc')


    // const fetch_instrumentation = new FetchInstrumentation({
    //     propagateTraceHeaderCorsUrls: [
    //         new RegExp("http://localhost:8089.*"),
    //         new RegExp("http://localhost:3000.*"),
    //         new RegExp("https://sandbox.env.mirrortab.com/api.*"),
    //         new RegExp("http://sandbox.env.mirrortab.com/api.*"),
    //         new RegExp("http://.*.env.mirrortab.com/api.*"),
    //         new RegExp("https://.*.env.mirrortab.com/api.*"),
    //         // new RegExp(".*")
    //     ]
    // });
    // const uii = new UserInteractionInstrumentation({
    //     eventNames: ['submit', 'click', 'keypress'],
    // })

    // fetch_instrumentation.setTracerProvider(tracerProvider)
    // registerInstrumentations({
    //     instrumentations: [fetch_instrumentation]
    // })
    //
    // if (conf.instrumentations) {
    //     registerInstrumentations({
    //         instrumentations: conf.instrumentations,
    //     })
    // }

    return tracerProvider;
}

function setupOnError(): void {
    const oldHandler = window.onerror

    window.onerror = function traceOnErrorHandler(
        message: string | Event,
        file?: string,
        line?: number,
        column?: number,
        err?: Error,
    ) {
        if (oldHandler) {
            oldHandler(message, file, line, column, err)
        }

        if (err) {
            reportException(err, { onerror: true })
            return
        }

        if (message === 'Script error.') {
            return
        }

        const attrs: Attributes = {
            'window.onerror': true,
        }
        if (file) {
            attrs['code.filepath'] = file
        }
        if (line) {
            attrs['code.lineno'] = line
        }
        if (column) {
            attrs['code.colno'] = column
        }
        reportException(String(message), attrs)
    }
}

export function shutdown(): Promise<void> {
    // TODO: provide shutdown
    return Promise.resolve()
}
