import { Sync } from "@mui/icons-material";
import {
  CircularProgress,
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Fab,
  Slide,
  Select,
  MenuItem,
} from "@mui/material";
import { IEnvironment, useEnvironmentsList } from "../../hooks/environments";
import * as React from "react";
import { InfoTable } from "../Debug";
import { useVersionsForProject } from "../../hooks/versions";
import moment from "moment";
import { useConfigContext } from "../../webappConfig";

type EnvironmentCardProps = {
  environment: IEnvironment;
  onChange?: (newValue: IEnvironment) => any;
};

type VersionSelector = {
  project: string;
  label: string;
  value?: string | null;
  setValue: (id: string | null) => void;
};

export const VersionSelector: React.FC<VersionSelector> = (props) => {
  const { versions } = useVersionsForProject(props.project);
  const options = versions.map((v) => ({
    id: v.shortSha,
    label: `${v.shortSha} — ${v.message}  (${moment(v.created).fromNow()})`,
  }));

  return (
    <Select
      sx={{ width: 600 }}
      value={props.value}
      onChange={(e) => props.setValue(e.target.value)}
    >
      {options.map((o) => (
        <MenuItem value={o.id}>{o.label}</MenuItem>
      ))}
    </Select>
  );
};

export const EnvironmentCard: React.FC<EnvironmentCardProps> = (props) => {
  const { slug } = props.environment;
  const keys = ["controlPlane", "app", "engine"];
  const [values, setValues] = React.useState<{ [key: string]: string | null }>(
    props.environment as any
  );

  const projectName: { [key: string]: string } = {
    controlPlane: "mt-control-plane",
    app: "mt-demo",
    engine: "webrtc-desktop",
  };

  const labels: { [key: string]: string } = {
    controlPlane: "Control Plane",
    app: "Demo App",
    engine: "Engine",
  };

  const getValue = (key: string) => values[key];

  const setValue = (key: string, value: string | null) => {
    setValues((existing) => ({ ...existing, [key]: value }));
    if (props.onChange) {
      props.onChange({
        ...values,
        slug,
      } as IEnvironment);
    }
  };

  return (
    <Card style={{ minHeight: 100, marginBottom: 50 }}>
      <Typography variant="h4">{slug}</Typography>
      <Grid container>
        <Grid item xl={6}>
          <InfoTable
            rows={keys.map((key) => [
              key,
              <VersionSelector
                value={getValue(key)}
                setValue={(v) => setValue(key, v)}
                project={projectName[key]}
                label={labels[key]}
              />,
            ])}
          />
        </Grid>
        <Grid item xl={6}>
          <Box>
            <Button variant="outlined">View list of sessions</Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

const floatingActionButtonStyle: React.CSSProperties = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};

export const Environments: React.FC = () => {
  const { webAppConfig } = useConfigContext();
  const apiBaseUrl = webAppConfig.apiBaseUrl();
  const { environments: initialEnvironments, loading } = useEnvironmentsList();
  const [environments, setEnvironments] = React.useState<{
    [key: string]: IEnvironment;
  }>({});
  const [showSync, setShowSync] = React.useState(false);
  const [syncing, setSyncing] = React.useState(false);

  const syncEnvironments = async () => {
    setSyncing(true);

    const mergeRequest = await window.fetch(`${apiBaseUrl}/hub/environments`, {
      method: "PUT",
      body: JSON.stringify({
        environments: Object.keys(environments).reduce((arr, key) => {
          return {
            ...arr,
            [key]: {
              cp_version: environments[key].controlPlane,
              app_version: environments[key].app,
              engine_version: environments[key].engine,
            },
          };
        }, {}),
      }),
    });

    setSyncing(false);
    const mr = await mergeRequest.json();
    window.location.href = mr.url;
  };

  React.useEffect(() => {
    setEnvironments(
      initialEnvironments.reduce((arr, item) => {
        return {
          ...arr,
          [item.slug]: {
            ...item,
          },
        };
      }, {})
    );
  }, [initialEnvironments]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Box marginTop={4}>
        <h1>Environments</h1>
        <Box marginTop={3}>
          <Grid container>
            {Object.keys(environments)
              .sort()
              .map((environment) => (
                <Grid item width={"100%"}>
                  <EnvironmentCard
                    onChange={(newValues) => {
                      setShowSync(true);
                      setEnvironments({
                        ...environments,
                        [environment]: newValues,
                      });
                    }}
                    environment={environments[environment]}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
      <Slide direction="up" in={showSync}>
        <Fab
          onClick={() => syncEnvironments()}
          style={floatingActionButtonStyle}
          color="primary"
          variant="extended"
        >
          <Sync sx={{ mr: 1 }} />
          {syncing ? <CircularProgress /> : "Sync environments"}
        </Fab>
      </Slide>
    </>
  );
};
