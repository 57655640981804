import React, { useEffect } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {
  SiteWorkspaceContextProvider,
  useSiteWorkspaceContext,
} from "./SiteWorkspaceContext";

type SiteContainerProps = {
  children?: React.ReactNode;
};

export const SiteContainer: React.FC<SiteContainerProps> = (props) => {
  return (
    <Box>
      <SiteWorkspaceContextProvider>
        <SiteContainerContents {...props} />
      </SiteWorkspaceContextProvider>
    </Box>
  );
};

const sitesTheme = createTheme({});

export const blueLineColor = "rgba(16, 134, 205, 0.2)";
export const blueBackgroundColor = "rgba(16, 134, 205, 0.1)";
export const blueSolidColor = "rgba(16, 134, 205, 1)";

const SiteContainerContents: React.FC<SiteContainerProps> = (props) => {
  const {
    workspaceId,
    workspaceListData,
    setSelectedWorkspace,
    loading: workspaceLoading,
  } = useSiteWorkspaceContext();

  if (!setSelectedWorkspace) throw Error("setSelectedWorkspace is missing");

  const body = workspaceLoading ? <CircularProgress /> : props.children;

  return <ThemeProvider theme={(global) => ({...global, sitesTheme})}>{body}</ThemeProvider>;
};
