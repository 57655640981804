import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardSharp";
import { useConfigContext } from "../webappConfig";

type ConfigDrawerProps = {};

const variables = [
  "MODE",
  "ENVIRONMENT",
  "API_BASE_URL",
  "SESSION_WEBSOCKET_BASE_URL",
  "CONTROL_PLANE_WEBSOCKET_BASE_URL",
  "SITES_SLUG",
  "TRACING_ENABLED",
  "CSP_DOMAINS_CSV",
  "VERSION",
];
const readOnlyVariables = ["VERSION"];

function arrayToObject(arr: [string, any][]): { [key: string]: any } {
  return arr.reduce((acc: any, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
}

type StringVariableValueDictionary = { [key: string]: string | null };

export const ConfigDrawer: React.FC<ConfigDrawerProps> = (props) => {
  const { runtimeVariableGetter, runtimeVariableSetter } = useConfigContext();

  const {
    webAppConfig: { configurable },
  } = useConfigContext();

  const [open, setOpen] = React.useState(false);

  const [formValues, setFormValues] =
    React.useState<StringVariableValueDictionary>(
      arrayToObject(
        variables.map((key) => [key, runtimeVariableGetter.get(key)])
      )
    );

  const set = (key: string, value: string | null) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const save = () => {
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];

      if (value != null) {
        runtimeVariableSetter.set(key, value);
      }
    });
    window.location.reload();
  };

  const reset = () => {
    runtimeVariableSetter.reset(Object.keys(formValues));
    window.location.reload();
  };

  const toolbar = (
    <Toolbar>
      <IconButton
        onClick={() => setOpen(false)}
        edge="start"
        color="inherit"
        aria-label="close"
      >
        <ArrowDownwardIcon />
      </IconButton>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Configuration
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <Button
          onClick={() => save()}
          variant="contained"
          color="primary"
          sx={{ flex: 1 }}
        >
          Save
        </Button>
        <Button
          onClick={() => reset()}
          variant="outlined"
          color="error"
          sx={{ flex: 1 }}
        >
          Reset
        </Button>
      </Box>
    </Toolbar>
  );

  return (
    <>
      {!open && configurable() && (
        <IconButton
          onClick={() => setOpen(true)}
          style={{ zIndex: 1050, position: "fixed", bottom: 20, left: 20 }}
          color="primary"
          aria-label="open drawer"
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
      <Drawer anchor="bottom" open={open}>
        {toolbar}
        <Divider />
        <Box padding={2}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {variables.map((key) => (
                  <TableRow key={key}>
                    <TableCell style={{ width: 300 }}>{key}</TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        value={formValues[key]}
                        onChange={(e) => set(key, e.target.value)}
                        disabled={readOnlyVariables.indexOf(key) > -1}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Drawer>
    </>
  );
};
