import { useAuth0 } from "@auth0/auth0-react";
import { Coordinates } from "../../types/Sesssion";
import { useConfigContext } from "../../webappConfig";
import { mtFetch } from "../utils";
import React from "react";

const getLocalStorageCoordinatesForSlug = (
  slug: string
): Coordinates | null => {
  try {
    JSON.parse(localStorage.getItem(`${slug}`) ?? "") as Coordinates;

    return null;
  } catch (e) {
    return null;
  }
};

export const useCoordinatesForSlug = (slug: string): Coordinates | null => {
  const [coordinates, setCoordinates] = React.useState<Coordinates | null>(
    null
  );

  const { getAccessTokenSilently } = useAuth0();
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();

  const load = async () => {
    const existingCoordinates = getLocalStorageCoordinatesForSlug(slug);

    if (existingCoordinates) {
      const isValid = await mtFetch(
        `${API_BASE_URL}/session/${existingCoordinates.namespace}/${existingCoordinates.id}/valid`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      );

      const isValidJson = await isValid.json();

      if (isValidJson.exists) {
        setCoordinates(existingCoordinates);
        return;
      } else {
        removeExistingCoordinatesForSlug(slug);
      }
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  return coordinates;
};

export const removeExistingCoordinatesForSlug = (slug: string) => {
  localStorage.removeItem(slug);
  window.location.reload();
};
