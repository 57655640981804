import React from "react";
import { ConfigGetter } from "./lib/config/configGetter";
import {
  configGetter,
  runtimeVariableGetter,
  runtimeVariableSetter,
} from "./lib/config";
import {
  IRuntimeVariableGetter,
  IRuntimeVariableSetter,
} from "./lib/config/runtimeVariableGetter";

export const SlugModeString = "slug";
export const ConsoleModeString = "console";
export const LocalEngineModeString = "local-engine";

interface IConsoleMode {
  type: typeof ConsoleModeString;
}

interface ISlugSiteMode {
  type: typeof SlugModeString;
  slug: string;
}

interface ILocalEngineMode {
  type: typeof LocalEngineModeString;
}

const ConsoleMode = (): IConsoleMode => ({
  type: ConsoleModeString,
});

const SlugSiteMode = (slug: string): ISlugSiteMode => ({
  type: SlugModeString,
  slug,
});

const LocalEngineMode = (): ILocalEngineMode => ({
  type: LocalEngineModeString,
});

type AppMode = IConsoleMode | ISlugSiteMode | ILocalEngineMode;

interface IWebAppConfig {
  mode(): AppMode;
  sitesSlug(): string | null;
  apiBaseUrl(): string;
  environment(): string;
  sessionWebsocketBaseUrl(): string;
  controlPlaneWebsocketBaseUrl(): string;
  isDebug(): boolean;
  tracingEnabled(): boolean;
  configurable(): boolean;
  cspDomains(): string[];
}

class ThrowErrorWebAppConfig implements IWebAppConfig {
  mode(): AppMode {
    throw new Error("Method not implemented.");
  }
  sitesSlug(): string | null {
    throw new Error("Method not implemented.");
  }
  apiBaseUrl(): string {
    throw new Error("Method not implemented.");
  }
  environment(): string {
    throw new Error("Method not implemented.");
  }
  sessionWebsocketBaseUrl(): string {
    throw new Error("Method not implemented.");
  }
  controlPlaneWebsocketBaseUrl(): string {
    throw new Error("Method not implemented.");
  }
  isDebug(): boolean {
    throw new Error("Method not implemented.");
  }
  tracingEnabled(): boolean {
    throw new Error("Method not implemented.");
  }
  configurable(): boolean {
    throw new Error("Method not implemented.");
  }
  cspDomains(): string[] {
    throw new Error("Method not implemented.");
  }
}

export class WebAppConfig implements IWebAppConfig {
  private configGetter: ConfigGetter;

  constructor(configGetter: ConfigGetter) {
    this.configGetter = configGetter;
  }

  mode = (): AppMode => {
    const modeString = this.configGetter.maybeGetString("MODE") ?? "console";

    return (() => {
      switch (modeString) {
        case SlugModeString:
          const slug = this.configGetter.maybeGetString("SITES_SLUG");
          if (!slug) {
            throw Error("slug mode requires a SLUG but is not present");
          }
          return SlugSiteMode(slug);
        case LocalEngineModeString:
          return LocalEngineMode();
        default:
          return ConsoleMode();
      }
    })();
  };

  sitesSlug = (): string | null => {
    return this.configGetter.maybeGetString("SITES_SLUG");
  };

  apiBaseUrl = (): string => {
    return this.configGetter.getString("API_BASE_URL");
  };

  environment = (): string => {
    return this.configGetter.maybeGetString("ENVIRONMENT") ?? "local";
  };

  sessionWebsocketBaseUrl = (): string => {
    return this.configGetter.getString("SESSION_WEBSOCKET_BASE_URL");
  };

  controlPlaneWebsocketBaseUrl = (): string => {
    return this.configGetter.getString("CONTROL_PLANE_WEBSOCKET_BASE_URL");
  };

  isDebug = (): boolean => {
    return this.environment().indexOf("sandbox") > -1;
  };

  tracingEnabled = (): boolean => {
    return this.configGetter.getBoolean("TRACING_ENABLED") || false;
  };

  configurable = (): boolean => {
    return Boolean(process.env[`REACT_APP_CONFIGURABLE`]);
  };

  cspDomains = (): string[] => {
    return this.configGetter.getString("CSP_DOMAINS_CSV").split(",");
  };
}

type ConfigContext = {
  webAppConfig: IWebAppConfig;
  runtimeVariableGetter: IRuntimeVariableGetter;
  runtimeVariableSetter: IRuntimeVariableSetter;
};

const configDefaultValue = {
  webAppConfig: new WebAppConfig(configGetter),
  runtimeVariableGetter,
  runtimeVariableSetter,
};

const ConfigContext = React.createContext<ConfigContext>(configDefaultValue);

export const ConfigContextProvider = (props: { children: React.ReactNode }) => {
  return (
    <ConfigContext.Provider value={configDefaultValue}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => React.useContext(ConfigContext);

export const useDebugLogger = () => {
  const {
    webAppConfig: { isDebug },
  } = useConfigContext();

  const debugLog = (message: string, other?: any) => {
    if (isDebug()) {
      console.debug(message, other);
    }
  };

  return {
    debugLog,
  };
};
