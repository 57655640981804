import {
  IRuntimeVariableGetter,
  IRuntimeVariableSetter,
} from "./runtimeVariableGetter";

export class ConfigGetter {
  private runtimeVariableGetter: IRuntimeVariableGetter;

  constructor(runtimeVariableGetter: IRuntimeVariableGetter) {
    this.runtimeVariableGetter = runtimeVariableGetter;
  }

  getString(envVar: string): string {
    const value = this.runtimeVariableGetter.get(envVar);
    if (value === null || value === undefined) {
      throw new Error(`missing runtime string ${envVar}`);
    }
    return value;
  }

  maybeGetString(envVar: string): string | null {
    try {
      return this.getString(envVar);
    } catch (error) {
      return null;
    }
  }

  getInteger(envVar: string): number {
    const stringValue = this.getString(envVar);
    const integerValue = parseInt(stringValue, 10);
    if (isNaN(integerValue)) {
      throw new Error(
        `Value of environment variable ${envVar} is not a valid integer`
      );
    }
    return integerValue;
  }

  maybeGetInteger(envVar: string): number | null {
    try {
      return this.getInteger(envVar);
    } catch (error) {
      return null;
    }
  }

  getDecimal(envVar: string): number {
    const stringValue = this.getString(envVar);
    const decimalValue = parseFloat(stringValue);
    if (isNaN(decimalValue)) {
      throw new Error(
        `Value of environment variable ${envVar} is not a valid decimal number`
      );
    }
    return decimalValue;
  }

  maybeGetDecimal(envVar: string): number | null {
    try {
      return this.getDecimal(envVar);
    } catch (error) {
      return null;
    }
  }

  getBoolean(envVar: string): boolean {
    const stringValue = this.getString(envVar);
    if (stringValue.toLowerCase() === "true") {
      return true;
    } else if (stringValue.toLowerCase() === "false") {
      return false;
    } else {
      throw new Error(
        `Value of environment variable ${envVar} is not a valid boolean`
      );
    }
  }

  maybeGetBoolean(envVar: string): boolean | null {
    try {
      return this.getBoolean(envVar);
    } catch (error) {
      return null;
    }
  }
}
