import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { gql } from "../__generated__";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { blueLineColor } from "./SiteContainer";

type SiteWorkspaceCreateProps = {};

const CREATE_WORKSPACE_MUTATION = gql(`
  mutation CreateWorkspace($title: String!) {
    createSiteWorkspace(input: { title: $title }) {
      created
      workspace {
        id
        title
      }
      error
    }
  }
`);

export const SiteWorkspaceCreate: React.FC<SiteWorkspaceCreateProps> = () => {
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [newWorkspaceTitle, setNewWorkspaceTitle] = React.useState("");
  const [createWorkspaceMutation] = useMutation(CREATE_WORKSPACE_MUTATION);
  const { refetchWorkspaces } = useSiteWorkspaceContext();

  const createWorkspace = async () => {
    setSaving(true);
    const data = await createWorkspaceMutation({
      variables: {
        title: newWorkspaceTitle,
      },
    });
    setSaving(false);

    if (!data.data?.createSiteWorkspace.created)
      throw Error("site workspace not created");

    await refetchWorkspaces?.();
    navigate(
      `/app/sites/workspaces/${data.data?.createSiteWorkspace.workspace?.id}`
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box display="flex" flexDirection={"column"} alignItems={"center"}>
        <Box minWidth={40}>
          <TextField
            value={newWorkspaceTitle}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "white",
              "& .MuiFilledInput-root": {
                backgroundColor: "white",
              },
              "& .MuiFilledInput-input": {
                fontSize: "18pt",
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: blueLineColor,
              },
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: blueLineColor,
              },
              width: "400px"
            }}
            placeholder="New Workspace Title"
            onChange={(e) => setNewWorkspaceTitle(e.target.value)}
          />
        </Box>
        <Box marginTop={3}>
          <Button
            variant="contained"
            disabled={saving}
            size={"large"}
            onClick={() => createWorkspace()}
          >
            {saving ? <CircularProgress /> : "Create Workspace"}
          </Button>
        </Box>
        <Box minHeight={200}></Box>
      </Box>
    </Box>
  );
};
