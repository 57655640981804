import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Box,
  Divider, Button,
} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {blueSolidColor} from "./SiteContainer";
import {useSiteWorkspaceContext} from "./SiteWorkspaceContext";
import {WorkOff, WorkOutlined, WorkRounded, WorkSharp, WorkTwoTone} from "@mui/icons-material"

type AvatarDropdownProps = {
  user: {
    name: string;
    email: string;
  };
  logout: () => void;
  workspaceItemWidth?: number;
};

export const AvatarDropdown: React.FC<AvatarDropdownProps> = ({
                                                                user,
                                                                logout,
                                                                workspaceItemWidth = 300,
                                                              }) => {
  const {workspaceId, workspaceListData, loading: workspaceLoading} =
    useSiteWorkspaceContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (workspaceLoading) {
    return <Typography>Loading Workspaces...</Typography>;
  }

  return (
    <div>
      <AvatarDropdownButton
        workspaceId={workspaceId ?? null}
        userEmail={user.email}
        onClick={handleMenu}
      />
      <AvatarDropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        user={user}
        logout={logout}
        workspaceItemWidth={workspaceItemWidth}
        workspaceListData={workspaceListData}
      />
    </div>
  );
};

const AvatarDropdownButton: React.FC<{
  userEmail: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
  workspaceId: string | null;
}> = ({
        userEmail,
        onClick,
        workspaceId,
      }) => (
  <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={onClick}
    color="inherit"
  >
    <Avatar
      sx={{
        width: 40,
        height: 40,
        bgcolor: blueSolidColor,
        fontSize: 15,
        fontWeight: "bold",
      }}
    >
      {userEmail.substring(0, 2).toUpperCase()}
    </Avatar>
  </IconButton>
);

const AvatarDropdownMenu: React.FC<{
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  user: { name: string; email: string };
  logout: () => void;
  workspaceItemWidth: number;
  workspaceListData: any;
}> = ({
        anchorEl,
        handleClose,
        user,
        logout,
        workspaceItemWidth,
        workspaceListData,
      }) => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <AvatarDropdownUserInfo user={user}/>
      <Divider/>
      <AvatarDropdownWorkspaceList
        workspaceItemWidth={workspaceItemWidth}
        handleClose={handleClose}
      />
      <Divider/>
      <AvatarDropdownMenuList
        handleNavigate={handleNavigate}
        handleClose={handleClose}
        logout={logout}
      />
    </Menu>
  );
};

const AvatarDropdownUserInfo: React.FC<{ user: { name: string; email: string } }> = ({
                                                                                       user
                                                                                     }) => (
  <Box sx={{padding: 2, minWidth: 200}}>
    <Typography variant="subtitle1" fontWeight="bold">
      {user.name}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {user.email}
    </Typography>
  </Box>
);

const AvatarDropdownMenuList: React.FC<{
  handleNavigate: (path: string) => void;
  handleClose: () => void;
  logout: () => void;
}> = ({handleNavigate, handleClose, logout}) => (
  <MenuList>
    <MenuItem onClick={() => handleNavigate("/app/profile")}>Profile</MenuItem>
    <MenuItem
      onClick={() => {
        handleClose();
        logout();
      }}
    >
      Logout
    </MenuItem>
  </MenuList>
);

const AvatarDropdownWorkspaceList: React.FC<{
  workspaceItemWidth: number;
  handleClose: () => any;
}> = ({workspaceItemWidth, handleClose}) => {
  const {workspaceId, workspaceListData, setSelectedWorkspace} = useSiteWorkspaceContext();
  const navigate = useNavigate();

  if (!setSelectedWorkspace) throw Error("setSelectedWorkspace is missing")

  return (
    <Box sx={{padding: 1}}>
      {workspaceListData?.viewer.user.siteWorkspaceUserAssociations.map(
        (workspace: any) => (
          <MenuItem
            key={workspace.siteWorkspace.id}
            onClick={
              () => {
                setSelectedWorkspace(workspace.siteWorkspace.id)
                handleClose()
              }
            }
            sx={{
              maxWidth: workspaceItemWidth,
              display: "block",
              whiteSpace: "nowrap",
              position: "relative",
              overflow: "hidden",
              ":before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                width: "50px",
                height: "100%",
                background:
                  "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
              },
            }}
          >
            <Box marginLeft={"-10px"} display={"flex"} flexDirection={"row"} alignItems={"left"}>
              {workspace.siteWorkspace.id == workspaceId ? <WorkOutlined/> : <WorkTwoTone/>}
              <Box marginLeft={"10px"}>{workspace.siteWorkspace.title}</Box>
            </Box>
          </MenuItem>
        )
      )}
      <MenuItem>
        <Button onClick={() => {
          navigate("/app/sites/create-workspace");
          handleClose();
        }} variant={"contained"} fullWidth>Create new
          workspace</Button>
      </MenuItem>
    </Box>
  )
}

