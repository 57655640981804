// consts
export const MAX_WIDTH = 3096;
export const MAX_HEIGHT = 2160;

export const calcDimensions = () => {
  const aspectRatio = window.innerHeight / window.innerWidth;
  let width = Math.floor(window.innerWidth * window.devicePixelRatio);
  let height = Math.floor(window.innerHeight * window.devicePixelRatio);

  // Ensure width and height are even numbers
  width -= width % 2;
  height -= height % 2;

  if (width > MAX_WIDTH) {
    width = MAX_WIDTH;
    height = Math.floor(width * aspectRatio);
  } else if (height > MAX_HEIGHT) {
    height = MAX_HEIGHT;
    width = Math.floor(height / aspectRatio);
  }

  // Ensure width and height are even numbers
  width -= width % 2;
  height -= height % 2;

  return [width, height];
};

export const adjustedPixelRatio = () => {
  return calcDimensions()[0] / window.innerWidth;
};

// take in videoref.current as arg
export const adjustVideoSize = (video: HTMLVideoElement | null) => {
  if (!video) {
    return;
  }

  let width = window.innerWidth;
  let height = window.innerHeight;

  if (width % 2 !== 0) {
    width -= 1;
  }
  if (height % 2 !== 0) {
    height -= 1;
  }

  video.style.width = `${width}px`;
  video.style.height = `${height}px`;
};
