export interface IRuntimeVariableGetter {
  get(envVar: string): string | null;
}

export interface IRuntimeVariableSetter {
  set(envVar: string, value: string): void;
  reset(keys: string[]): void;
}

export class LocalStorageRuntimeVariableGetterAndSetter
  implements IRuntimeVariableGetter, IRuntimeVariableSetter
{
  private prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  reset(keys: string[]): void {
    keys.forEach((envVar) =>
      localStorage.removeItem(`${this.prefix}_${envVar}`)
    );
  }

  get(envVar: string): string | null {
    const value = localStorage.getItem(`${this.prefix}_${envVar}`);
    return value !== null ? value : null;
  }

  set(envVar: string, value: string | null): void {
    localStorage.setItem(`${this.prefix}_${envVar}`, value ?? "");
  }
}

export class CreateReactAppCompiledRuntimeVariableGetter
  implements IRuntimeVariableGetter
{
  get(envVar: string): string | null {
    return process.env[`REACT_APP_${envVar}`] || null;
  }
}

export class WindowGlobalRuntimeVariableGetter
  implements IRuntimeVariableGetter
{
  private prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  get(envVar: string): string | null {
    return (window as any).env?.[`${this.prefix}_${envVar}`] ?? null;
  }
}

export class LocalStorageRuntimeVariableGetter
  implements IRuntimeVariableGetter
{
  private prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  get(envVar: string): string | null {
    return (window as any).env?.[`${this.prefix}_${envVar}`] ?? null;
  }
}

export class OrderedRuntimeVariableGetter implements IRuntimeVariableGetter {
  private runtimeVariableGetters: IRuntimeVariableGetter[];

  constructor(runtimeVariableGetters: IRuntimeVariableGetter[]) {
    this.runtimeVariableGetters = runtimeVariableGetters;
  }

  get(envVar: string): string | null {
    for (const getter of this.runtimeVariableGetters) {
      const value = getter.get(envVar);
      if (value !== null) {
        return value;
      }
    }
    return null;
  }
}
