import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { gql } from "../__generated__";
import { useMutation } from "@apollo/client";
import React from "react";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { useNavigate } from "react-router-dom";
import { InfoTable } from "./Debug";
import { filterNotNull, isValidUrl } from "../lib/helper";
import { blueLineColor } from "./SiteContainer";

type SiteCreateProps = {};

const CREATE_HOSTED_SITE_MUTATION = gql(`
mutation CreateHostedSiteMutation($title: String!, $description: String, $workspaceId: String!, $scale: Int!) {
  createHostedSite(input: { title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const CREATE_PROXY_SITE_MUTATION = gql(`
mutation CreateProxySiteMutation($title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {
  createProxySite(input: { title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const HOSTED = "hosted";
const PROXY = "proxy";
type SiteType = typeof HOSTED | typeof PROXY;

export const SiteCreate: React.FC<SiteCreateProps> = (props) => {
  const textFieldStyle = {
    backgroundColor: "white",
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },
    "& .MuiFilledInput-input": {
      fontSize: "18pt",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: blueLineColor,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: blueLineColor,
    },
    width: "700px",
  };

  const navigate = useNavigate();
  const { workspaceId, refetchWorkspace } = useSiteWorkspaceContext();
  const [createHostedSiteMutation, {}] = useMutation(
    CREATE_HOSTED_SITE_MUTATION
  );
  const [createProxySiteMutation, {}] = useMutation(CREATE_PROXY_SITE_MUTATION);
  const [saving, setSaving] = React.useState(false);
  const [siteType, _setSiteType] = React.useState<SiteType | null>(HOSTED);

  const [title, _setTitle] = React.useState("");
  const [description, _setDescription] = React.useState("");
  const [proxyUrl, _setProxyUrl] = React.useState("");
  const [scale, _setScale] = React.useState(0);

  const [scaleError, setScaleError] = React.useState<string | null>("");
  const [titleError, setTitleError] = React.useState<string | null>("");
  const [descriptionError, setDescriptionError] = React.useState<string | null>(
    ""
  );
  const [proxyUrlError, setProxyUrlError] = React.useState<string | null>("");

  const setSiteType = (value: SiteType) => {
    _setSiteType(value);
    switch (value) {
      case HOSTED:
        setProxyUrlError(null);
        setProxyUrl("");
        break;
      default:
        break;
    }
  };

  const setScale = (value: number) => {
    _setScale(value);

    if (value < 0 || value > 20) {
      setScaleError("Value must be between 0 and 20");
    } else {
      setScaleError(null);
    }
  };

  const setProxyUrl = (value: string) => {
    _setProxyUrl(value);

    if (!value || !isValidUrl(value)) {
      setProxyUrlError("Proxy URL is required and must be a URL");
    } else {
      setProxyUrlError(null);
    }
  };

  const setTitle = (value: string) => {
    _setTitle(value);

    if (!value) {
      setTitleError("Title is required");
    } else {
      setTitleError(null);
    }
  };

  const setDescription = (value: string) => {
    _setDescription(value);

    if (!value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError(null);
    }
  };

  const submit = async () => {
    if (!siteType) throw Error("no site type");
    if (!title) throw Error("no title");
    if (!workspaceId) throw Error("no workspace id");

    switch (siteType) {
      case HOSTED:
        setSaving(true);
        const createdHostedSite = await createHostedSiteMutation({
          variables: {
            title,
            description,
            workspaceId,
            scale,
          },
        });

        if (createdHostedSite.data?.createHostedSite.created) {
          setSaving(false);
          refetchWorkspace?.();
          navigate(
            `/app/sites/workspaces/${workspaceId}/site/${createdHostedSite.data.createHostedSite.site?.id}`
          );
        }
        break;
      case PROXY:
        setSaving(true);
        const createdProxySite = await createProxySiteMutation({
          variables: {
            title,
            description,
            workspaceId,
            proxyUrl,
            scale,
          },
        });

        if (createdProxySite.data?.createProxySite.created) {
          setSaving(false);
          refetchWorkspace?.();
          navigate(
            `/app/sites/workspaces/${workspaceId}/site/${createdProxySite.data.createProxySite.site?.id}`
          );
        }
        break;
      default:
        throw Error("invalid site type");
    }
  };

  const createDisabled =
    saving ||
    !title ||
    !description ||
    !siteType ||
    !!titleError ||
    !!descriptionError ||
    !!scaleError ||
    (siteType == PROXY && !!proxyUrlError && siteType == PROXY && !proxyUrl);

  const buttonStyle = {
    borderRadius: "10px",
    padding: "10px 20px",
    width: "200px",
    color: "#666",
    border: "1px solid #ccc",
    "&:hover": {
      backgroundColor: blueLineColor,
    },
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#1976d2",
    color: "#fff",
    border: "1px solid #1976d2",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="row"
      alignItems="center"
      minHeight="80vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom="20px"
        width={"100%"}
      >
        <ButtonGroup>
          <Button
            sx={siteType === HOSTED ? selectedButtonStyle : buttonStyle}
            onClick={() => setSiteType(HOSTED)}
          >
            Hosted Site
          </Button>
          <Button
            sx={siteType === PROXY ? selectedButtonStyle : buttonStyle}
            onClick={() => setSiteType(PROXY)}
          >
            Mirror an Existing Site
          </Button>
        </ButtonGroup>
        <Box
          marginTop={"20px"}
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
        >
          <TextField
            value={title}
            variant="filled"
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Site title"
            error={!!titleError}
            label={titleError}
            fullWidth
            margin="dense"
            sx={textFieldStyle}
          />
          <TextField
            value={description}
            variant="filled"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Site description"
            error={!!descriptionError}
            label={descriptionError}
            fullWidth
            margin="dense"
            sx={textFieldStyle}
          />
          <TextField
            value={scale}
            type="number"
            variant="filled"
            onChange={(e) => setScale(parseInt(e.target.value))}
            placeholder="Scale"
            error={!!scaleError}
            label={scaleError}
            fullWidth
            margin="dense"
            sx={textFieldStyle}
          />
          {siteType === PROXY && (
            <Box marginBottom="10px">
              <TextField
                value={proxyUrl}
                variant="filled"
                onChange={(e) => setProxyUrl(e.target.value)}
                placeholder="Proxy URL"
                error={!!proxyUrlError}
                label={proxyUrlError}
                fullWidth
                margin="dense"
                sx={textFieldStyle}
              />
            </Box>
          )}
          <Box>
            <Button
              size="large"
              disabled={createDisabled}
              variant="contained"
              onClick={submit}
            >
              {saving ? <CircularProgress /> : "Create Site"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
