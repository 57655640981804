import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Fab,
  Link,
  Paper,
  Slider,
  Snackbar,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BaseBreadcrumbs } from "./SiteBreadcrumbs";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { useParams } from "react-router-dom";
import { gql } from "../__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { InfoTable } from "./Debug";
import moment from "moment";
import { useConfigContext } from "../webappConfig";
import React from "react";
import { ViewWithBreadcrumbs } from "./ViewWithBreadcrumbs";
import { SaveOutlined } from "@mui/icons-material";

type SiteDetailProps = {};

const SITE_QUERY = gql(`
query SiteQuery($id: String!) {
  site(id: $id) {
    id
    created
    slug
    stats {
      hits
    }
    type
    title
    scale
    description
    domains {
      id
      domainName
    }
    siteWorkspace {
      id
      slug
      title
      userAssociations {
        id
        created
        user {
          id
          emailAddress
        }
      }
    }
    ... on HostedSite {
      currentLiveDeployment {
        id
        created
        bundle {
          id
          hash
        }
      }
    }
    ... on ProxySite {
      url
    }
  }
}
`);

const UPDATE_HOSTED_SITE_MUTATION = gql(`
  mutation UpdateHostedSiteMutation($siteId: String!, $title: String!, $description: String, $scale: Int!) {
    updateHostedSite(input: { siteId: $siteId, title: $title, description: $description, scale: $scale}) {
      updated
      site {
        id
        title
        description
        scale
        siteWorkspace {
          id
        }
      }
      error
    }
  }
  `);

const UPDATE_PROXY_SITE_MUTATION = gql(`
  mutation UpdateProxySiteMutation($siteId: String!, $title: String!, $description: String, $proxyUrl: String!, $scale: Int!) {
    updateProxySite(input: { siteId: $siteId, title: $title, description: $description, proxyUrl: $proxyUrl, scale: $scale}) {
      updated
      site {
        id
        title
        description
        scale
        siteWorkspace {
          id
        }
      }
      error
    }
  }
  `);

export const SiteDetail: React.FC<SiteDetailProps> = (props) => {
  const {
    webAppConfig: { environment },
  } = useConfigContext();
  const ENVIRONMENT = environment();
  const { siteWorkspaceData, loading, workspaceId } = useSiteWorkspaceContext();
  const { siteId } = useParams();
  const [isEdited, setIsEdited] = React.useState(false);

  const [updateHostedSiteMutation] = useMutation(UPDATE_HOSTED_SITE_MUTATION);
  const [updateProxySiteMutation] = useMutation(UPDATE_PROXY_SITE_MUTATION);

  const { data: siteData, loading: siteLoading } = useQuery(SITE_QUERY, {
    variables: {
      id: siteId ?? "",
    },
    skip: !siteId,
    fetchPolicy: "no-cache",
  });

  const [title, setTitle] = React.useState(siteData?.site?.title ?? "");
  const [description, setDescription] = React.useState(
    siteData?.site?.description ?? ""
  );
  const [scale, setScale] = React.useState<number>(siteData?.site?.scale ?? 1);
  const [proxyUrl, setProxyUrl] = React.useState("");

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
    setErrorMessage(null);
  };

  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState({
    title: "",
    description: "",
    scale: "",
    proxyUrl: "",
  });

  const isValidUrl = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return !!parsedUrl.protocol && !!parsedUrl.host;
    } catch (e) {
      return false;
    }
  };

  const handleScaleChange = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number);
    setIsEdited(true);
  };

  const marks = Array.from({ length: 20 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const validateField = (
    field: "title" | "description" | "scale" | "proxyUrl"
  ) => {
    const newErrors = { ...errors };
    if (field === "title" && !title.trim()) {
      newErrors.title = "Title is required";
    } else {
      newErrors.title = "";
    }
    if (field === "description" && !description.trim()) {
      newErrors.description = "Description is required";
    } else {
      newErrors.description = "";
    }
    if (field === "scale" && (isNaN(scale) || scale < 1 || scale > 20)) {
      newErrors.scale = "Scale must be between 1 and 20";
    } else {
      newErrors.scale = "";
    }
    if (
      field === "proxyUrl" &&
      siteData?.site?.__typename === "ProxySite" &&
      (!proxyUrl.trim() || !isValidUrl(proxyUrl))
    ) {
      newErrors.proxyUrl = "Proxy URL is required and must be a valid URL";
    } else {
      newErrors.proxyUrl = "";
    }
    setErrors(newErrors);
  };

  const isFormValid = () => {
    const isProxySite =
      siteData?.site?.__typename === "ProxySite" ? !errors.proxyUrl : true;

    return !errors.title && !errors.description && !errors.scale && isProxySite;
  };
  const handleSave = async () => {
    if (!isFormValid()) return;

    setSaving(true);

    try {
      if (siteData?.site?.__typename === "HostedSite") {
        const { data } = await updateHostedSiteMutation({
          variables: {
            siteId: siteData.site.id,
            title,
            description,
            scale,
          },
        });

        if (data?.updateHostedSite?.error) {
          setErrorMessage(data.updateHostedSite.error);
          setOpenSnackbar(true);
        } else {
        }
      } else if (siteData?.site?.__typename === "ProxySite") {
        const { data } = await updateProxySiteMutation({
          variables: {
            siteId: siteData.site.id,
            title,
            description,
            proxyUrl,
            scale,
          },
        });

        if (data?.updateProxySite?.error) {
          setErrorMessage(data.updateProxySite.error);
          setOpenSnackbar(true);
        } else {
        }
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      setOpenSnackbar(true);
      console.error("Error updating site:", error);
    } finally {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (siteData?.site) {
      setTitle(siteData.site.title || "");
      setDescription(siteData.site.description || "");
      setScale(siteData.site.scale || 1);
      if (siteData.site.__typename === "ProxySite") {
        setProxyUrl(siteData.site.url || "");
      }
    }
  }, [siteData]);

  if (!siteWorkspaceData || loading || siteLoading) return <CircularProgress />;
  if (!siteData) return <span />;

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <ViewWithBreadcrumbs
        breadcrumbs={[
          <Link
            key="sites"
            href={"/app/sites"}
            underline="none"
            color="inherit"
          >
            <Typography variant="h4" fontWeight={300}>
              Sites
            </Typography>
          </Link>,
          <Link
            key={"workspace"}
            href={`/app/sites/workspaces/${siteWorkspaceData?.siteWorkspace?.id}`}
            underline="none"
            color="inherit"
          >
            <Typography fontWeight={300} variant="h4">
              {siteWorkspaceData?.siteWorkspace?.title}
            </Typography>
          </Link>,
          <Typography variant="h4">{siteData?.site?.title}</Typography>,
        ]}
        rightCta={<span />}
      >
        <Box>
          <Typography variant={"h5"} marginY={2}>
            Basic Information
          </Typography>
          <Box style={{ width: 600 }}>
            <InfoTable
              rows={[
                ["ID", <>{siteData?.site?.id}</>],
                [
                  "Created",
                  <>{moment(parseInt(siteData?.site.created, 10)).fromNow()}</>,
                ],
                [
                  "Title",
                  <TextField
                    fullWidth
                    value={title}
                    size="small"
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setIsEdited(true);
                    }}
                    onBlur={() => validateField("title")}
                    error={!!errors.title}
                    helperText={errors.title}
                  />,
                ],
                [
                  "Description",
                  <TextField
                    fullWidth
                    value={description}
                    size="small"
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setIsEdited(true);
                    }}
                    onBlur={() => validateField("description")}
                    error={!!errors.description}
                    helperText={errors.description}
                  />,
                ],
                [
                  "Scale",
                  <Slider
                    aria-label="Scale"
                    value={scale}
                    onChange={handleScaleChange}
                    valueLabelDisplay="auto"
                    step={1}
                    min={1}
                    max={10}
                    disabled={saving} // Disable the slider if saving
                  />,
                ],
                ["Slug", siteData.site.slug],
                [
                  "Type",
                  <Box>
                    <Box>{siteData.site.type}</Box>
                    <Box>
                      {siteData.site.__typename === "HostedSite" && (
                        <Box style={{ width: 600 }}>
                          <InfoTable
                            rows={[
                              [
                                "Latest deployment",
                                <>
                                  {siteData?.site?.currentLiveDeployment
                                    ?.created
                                    ? moment(
                                        parseInt(
                                          siteData?.site?.currentLiveDeployment
                                            ?.created ?? "",
                                          10
                                        )
                                      ).fromNow()
                                    : "(none)"}
                                </>,
                              ],
                            ]}
                          />
                        </Box>
                      )}

                      {siteData.site.__typename === "ProxySite" && (
                        <Box style={{ width: 600 }}>
                          <InfoTable
                            rows={[
                              [
                                "Proxy URL",
                                <TextField
                                  fullWidth
                                  value={proxyUrl}
                                  size="small"
                                  onChange={(e) => {
                                    setProxyUrl(e.target.value);
                                    setIsEdited(true);
                                  }}
                                  onBlur={() => validateField("proxyUrl")}
                                  error={!!errors.proxyUrl}
                                  helperText={errors.proxyUrl}
                                />,
                              ],
                            ]}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>,
                ],
              ]}
            />
          </Box>
        </Box>

        <Box>
          <Box
            justifyContent="space-between"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography marginY={2} variant={"h5"}>
              Domains
            </Typography>
            <Button disabled variant="contained">
              Add new domain
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>URL</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>Default (created by MirrorTab)</TableCell>
              <TableCell>
                <Link
                  href={`https://${ENVIRONMENT}.env.mirrortab.com/site/${siteData.site.slug}/`}
                >
                  {`https://${ENVIRONMENT}.env.mirrortab.com/site/${siteData.site.slug}/`}
                </Link>
              </TableCell>
              <TableCell>
                <Button variant="outlined" color="error" size="small" disabled>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableContainer>
        </Box>

        <Tooltip
          title={
            isFormValid() && isEdited ? "Save changes" : "Fix validation errors"
          }
        >
          <Fab
            color="primary"
            aria-label="save"
            style={{ position: "fixed", bottom: 16, right: 16 }}
            onClick={handleSave}
            disabled={!isFormValid() || !isEdited || saving} // Disable during saving
          >
            {saving ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <SaveOutlined />
            )}
          </Fab>
        </Tooltip>
      </ViewWithBreadcrumbs>
    </>
  );
};
