import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useGeoip } from "./geoip";
import { useConfigContext } from "../webappConfig";
import { mtFetch } from "../lib/utils";
import { useTracing } from "../index";

export const useTroubleshoot = () => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const API_BASE_URL = apiBaseUrl();
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("Troubleshoot");

  const { getGeoIp } = useGeoip();

  const submit = async (
    id: string,
    namespace: string,
    email: string,
    message: string
  ) => {
    const fp = await FingerprintJS.load();

    const { visitorId, components } = await fp.get();

    const response = await mtFetch(
      `${API_BASE_URL}/session/${namespace}/${id}/troubleshoot`,
      {
        method: "POST",
        body: JSON.stringify({
          email,
          message,
          userAgent: navigator.userAgent,
          fingerprint: {
            visitorId,
            serializedComponents: JSON.stringify(components),
          },
          clientInfoRaw: JSON.stringify(await getGeoIp()),
        }),
      },
      tracer
    );

    if (response.status !== 201) {
      throw Error(`response - ${response.status} - ${await response.text()}`);
    }
  };

  return {
    submit,
  };
};
