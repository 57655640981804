import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { blueLineColor } from "./SiteContainer";

type BreadcrumbsHeaderProps = {
  breadcrumbs: React.ReactNode[];
  rightCta?: React.ReactNode | null;
};

export const BreadcrumbsHeaderWithCTA: React.FC<BreadcrumbsHeaderProps> = ({
  breadcrumbs,
  rightCta,
}) => {
  const padding = 3;

  const separator = (
    <Typography color="rgba(23, 34, 43, 0.2)" fontSize={25}>
      |
    </Typography>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        paddingLeft: padding,
      }}
    >
      <Box>
        <Breadcrumbs separator={separator}>{breadcrumbs}</Breadcrumbs>
      </Box>
      <Box>{rightCta}</Box>
    </Box>
  );
};

type ContentBoxProps = {
  children: React.ReactNode;
};

export const ContentBox: React.FC<ContentBoxProps> = ({ children }) => {
  const padding = 3;
  const borderRadius = 4;

  return (
    <Box
      sx={{
        background: "white",
        borderRadius,
        padding,
        paddingTop: padding - 1,
        paddingBottom: padding - 1,
        minHeight: "70vh",
        border: `1px solid ${blueLineColor}`,
      }}
    >
      {children}
    </Box>
  );
};

type ViewWithBreadcrumbsProps = {
  breadcrumbs: React.ReactNode[];
  children: React.ReactNode;
  rightCta?: React.ReactNode | null;
};

export const ViewWithBreadcrumbs: React.FC<ViewWithBreadcrumbsProps> = ({
  breadcrumbs,
  children,
  rightCta,
}) => {
  return (
    <Box>
      <Box paddingTop={4} marginBottom={2}><BreadcrumbsHeaderWithCTA breadcrumbs={breadcrumbs} rightCta={rightCta} /></Box>
      <ContentBox>{children}</ContentBox>
    </Box>
  );
};
