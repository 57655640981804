import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { DefaultLayout } from "./shared/DefaultLayout";

interface IErrorProps {
  message: string;
  id: string;
}

type ErrorProps = IErrorProps

export const FullScreenError: React.FC<ErrorProps> = (props) => {
  return (
    <DefaultLayout>
      <Box marginTop={3}>
        <Alert id={props.id} severity="error">{props.message}</Alert>
      </Box>
    </DefaultLayout>
  )
}
