import { useConfigContext } from "../webappConfig";
import { useTracing } from "../index";
import { mtFetch } from "../lib/utils";

interface SlugInfo {
  url?: string | null;
  slug: string;
}

export const useSlug = () => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();
  const { tracerProvider } = useTracing();
  const tracer = tracerProvider?.getMTTracer("Slug");
  const API_BASE_URL = apiBaseUrl();

  const get = async (slug: string): Promise<SlugInfo> => {
    const data = await mtFetch(
      `${API_BASE_URL}/slug/${slug}`,
      {
        mode: 'cors',
        method: "GET",
      },
      tracer
    );

    const json = await data.json();
    return json;
  };

  return { get };
};
