import * as React from "react";

const DUMMY_GEOIP_DATA = {
  city: { name: "Roslindale", names: { en: "Roslindale" } },
  continent: {
    code: "NA",
    geoname_id: 6255149,
    names: {
      de: "Nordamerika",
      en: "North America",
      es: "Norteamérica",
      fa: " امریکای شمالی",
      fr: "Amérique Du Nord",
      ja: "北アメリカ大陸",
      ko: "북아메리카",
      "pt-BR": "América Do Norte",
      ru: "Северная Америка",
      "zh-CN": "北美洲",
    },
    name: "North America",
  },
  country: {
    geoname_id: 6252001,
    iso_code: "US",
    names: {
      de: "Vereinigte Staaten von Amerika",
      en: "United States",
      es: "Estados Unidos de América (los)",
      fa: "ایالات متحدهٔ امریکا",
      fr: "États-Unis",
      ja: "アメリカ合衆国",
      ko: "미국",
      "pt-BR": "Estados Unidos",
      ru: "США",
      "zh-CN": "美国",
    },
    name: "United States",
    name_native: "United States",
    phone_code: "1",
    capital: "Washington D.C.",
    currency: "USD,USN,USS",
    flag: "🇺🇸",
    languages: [{ iso_code: "en", name: "English", name_native: "English" }],
  },
  location: { latitude: 42.2796, longitude: -71.1282 },
  subdivisions: [{ names: { en: "Massachusetts" } }],
  state: { name: "Massachusetts" },
  datasource: [
    {
      name: "IP to City Lite",
      attribution: "<a href='https://db-ip.com'>IP Geolocation by DB-IP</a>",
      license: "Creative Commons Attribution License",
    },
  ],
  ip: "209.6.241.39",
};

export const useGeoip = () => {
  const getGeoIp = async () => {
    return DUMMY_GEOIP_DATA;
  };

  return {
    getGeoIp,
  };
};
