import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useConfigContext } from "../webappConfig";

interface IVersion {
  created: number;
  sha: string;
  shortSha: string;
  status: string;
  projectName: string;
  url: string;
  message: string;
}

export const useVersionsForProject = (project: string) => {
  const {
    webAppConfig: { apiBaseUrl },
  } = useConfigContext();

  const API_BASE_URL = apiBaseUrl();

  const [versions, setVersions] = React.useState<IVersion[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useCallback(async () => {
    setLoading(true);

    const data = await window.fetch(`${API_BASE_URL}/hub/versions/${project}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    });

    const json = await data.json();
    setVersions(json);
    setLoading(false);
  }, [setVersions, setLoading, project, getAccessTokenSilently]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    fetch,
    loading,
    versions,
  };
};
