import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Link,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import React from "react";
import { ViewWithBreadcrumbs } from "./ViewWithBreadcrumbs";
import moment from "moment";
import { gql, useMutation } from "@apollo/client";

type SiteWorkspaceDetailProps = {};

const SITES = "Sites";
const SETTINGS = "Settings";

type SiteWorkspaceDetailTab = typeof SITES | typeof SETTINGS;

const DELETE_SITE_MUTATION = gql(`
  mutation DeleteSiteMutation($siteId: String!) {
    deleteSite(input: { siteId: $siteId }) {
      deleted
      error
    }
  }
`);

export const SiteWorkspaceDetail: React.FC<SiteWorkspaceDetailProps> = () => {
  const navigate = useNavigate();

  const [pendingDeleteId, setPendingDeleteId] = React.useState<string | null>(
    null
  );

  const [deleteSiteMutation] = useMutation(DELETE_SITE_MUTATION);

  const confirmDeleteSite = async () => {
    if (!pendingDeleteId) return;

    try {
      const { data } = await deleteSiteMutation({
        variables: { siteId: pendingDeleteId },
      });

      if (data?.deleteSite?.deleted) {
        console.log("Site deleted successfully");
        setPendingDeleteId(null);

        window.location.reload();
      } else {
        console.error("Error deleting site:", data?.deleteSite?.error);
      }
    } catch (err) {
      console.error("Error during site deletion:", err);
    }
  };

  const handleDeleteClick = (siteId: string) => {
    setPendingDeleteId(siteId);
  };

  const handleCloseDeleteDialog = () => {
    setPendingDeleteId(null);
  };

  const {
    siteWorkspaceData,
    loading,
    workspaceId,
    workspaceListData,
    workspaceListLoading,
  } = useSiteWorkspaceContext();

  const [activeTab, setActiveTab] =
    React.useState<SiteWorkspaceDetailTab>(SITES);

  const workspaceSelector = (
    <Select
      value={workspaceId}
      onChange={(e) =>
        navigate(`/app/sites/workspaces/${e.target.value as string}`)
      }
    >
      {workspaceListData?.viewer.user.siteWorkspaceUserAssociations.map(
        (siteWorkspaceUserAssociation) => (
          <MenuItem
            key={siteWorkspaceUserAssociation.siteWorkspace.id}
            value={siteWorkspaceUserAssociation.siteWorkspace.id}
          >
            {siteWorkspaceUserAssociation.siteWorkspace.title}
          </MenuItem>
        )
      )}
    </Select>
  );

  if (loading) return <CircularProgress />;

  const sitesTab = (
    <Box paddingY={2}>
      <Box>
        {!siteWorkspaceData?.siteWorkspace.sites.length && (
          <Alert severity="info">
            There are no sites for this workspace. Create one below.
          </Alert>
        )}
      </Box>
      {siteWorkspaceData?.siteWorkspace.sites.map((site) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          key={site.id}
          marginBottom={2}
        >
          <Box>
            <Typography variant="h5">
              {site.title} ({site.type})
            </Typography>
            <Typography>{site.description}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Button
              onClick={() =>
                navigate(`/app/sites/workspaces/${workspaceId}/site/${site.id}`)
              }
              variant="outlined"
              size="small"
            >
              Manage
            </Button>
            <Button
              onClick={() => handleDeleteClick(site.id)}
              variant="outlined"
              size="small"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );

  const addSiteFab = (
    <Tooltip title="Create new site">
      <Fab
        color="primary"
        aria-label="add"
        onClick={() =>
          navigate(`/app/sites/workspaces/${workspaceId}/create-site`)
        }
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <AddOutlined />
      </Fab>
    </Tooltip>
  );

  const settingsTab = (
    <Box>
      <Box>
        <Typography variant="h4" marginY={3}>
          Workspace Settings
        </Typography>
        <Typography variant="h5" marginY={3} gutterBottom>
          Members
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Added to Workspace</strong>
                </TableCell>
                <TableCell>
                  <strong>Role</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {siteWorkspaceData?.siteWorkspace.userAssociations.map(
              (userAssociation) => (
                <TableRow key={userAssociation.id}>
                  <TableCell>{userAssociation.user.emailAddress}</TableCell>
                  <TableCell>
                    {moment(parseInt(userAssociation.created, 10)).fromNow()}
                  </TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>
                    <Button
                      disabled
                      variant="outlined"
                      size="small"
                      color="warning"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );

  return (
    <>
      {addSiteFab}
      <Dialog open={!!pendingDeleteId} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this site?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={confirmDeleteSite} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ViewWithBreadcrumbs
        breadcrumbs={[
          <Link key="sites" underline="none" color="inherit">
            <Typography variant="h4" fontWeight={400}>
              Sites
            </Typography>
          </Link>,
          <Link
            key={`sites/workspaces/${siteWorkspaceData?.siteWorkspace?.id}`}
            underline="none"
            color="inherit"
          >
            <Typography variant="h4">
              {siteWorkspaceData?.siteWorkspace?.title}
            </Typography>
          </Link>,
        ]}
        rightCta={<span />}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab value={SITES} label="Sites" />
            <Tab value={SETTINGS} label="Settings" />
          </Tabs>
        </Box>
        <Box>{activeTab === SITES && sitesTab}</Box>
        <Box>{activeTab === SETTINGS && settingsTab}</Box>
      </ViewWithBreadcrumbs>
    </>
  );
};
