import { Resource, IResource } from '@opentelemetry/resources'

export interface Config {
    // `service.name` resource attribute.
    serviceName?: string
    // `service.version` resource attribute.
    serviceVersion?: string
    // `deployment.environment` resource attribute.
    deploymentEnvironment?: string
    // Any other resource attributes.
    resourceAttributes?: Record<string, any>
    // resource that describes an entity that produces telemetry, for example,
    // such attributes as host.name and service.name. All produced spans and metrics
    // will have these attributes.
    //
    // resource overrides and replaces any other resource attributes.
    resource?: IResource
}

export function createResource(
    resource: IResource | undefined,
    resourceAttributes: Record<string, any> | undefined,
    serviceName: string,
    serviceVersion: string,
    deploymentEnvironment: string,
): IResource {
    if (resource) {
        return resource
    }

    const attrs: Record<string, any> = {}

    if (resourceAttributes) {
        Object.assign(attrs, resourceAttributes)
    }
    if (serviceName) {
        attrs['service.name'] = serviceName
    }
    if (serviceVersion) {
        attrs['service.version'] = serviceVersion
    }
    if (deploymentEnvironment) {
        attrs['deployment.environment'] = deploymentEnvironment
    }

    resource = Resource.default() as Resource

    if (Object.keys(attrs).length) {
        return resource.merge(new Resource(attrs))
    }

    return resource
}