import * as React from "react";
import { useParams } from "react-router-dom";
import { SessionViewer } from "./Session";

export const SessionWithSecret: React.FC = (props) => {
  const { secret, id, namespace } = useParams();

  if (!secret) throw Error();
  if (!namespace) throw Error();
  if (!id) throw Error();

  React.useEffect(() => {
    if (window.location.href.indexOf("test") === -1) {
      window.location.href = window.location.href + "#test";
    }
  }, []);

  return <SessionViewer {...{ id, namespace, secret }} />;
};
