import { Launch } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useGeoip } from "../hooks/geoip";
import {
  Session,
  useCreateSession,
  useDeleteSession,
  useSessionsList,
} from "../hooks/session";
import { bold, useList } from "../lib/helper";
import { EViewMode } from "../WebRTCClient";
import { InfoTable } from "./Debug";
import { useConfigContext } from "../webappConfig";

interface ICreateSessionModalProps {
  open: boolean;
  onClose: () => any;
}

type CreateSessionModalProps = ICreateSessionModalProps;

export const CreateSessionModal: React.FC<CreateSessionModalProps> = (
  props
) => {
  const [creating, setCreating] = React.useState(false);
  const [url, setUrl] = React.useState("https://google.com");
  const [count] = React.useState(1);
  const [webrtcDesktopVersion, setWebrtcDesktopVersion] = React.useState<
    string | null
  >();
  const [codec, setCodec] = React.useState("aurora4");
  const [ttl, setTtl] = React.useState(String(60 * 60));
  const { getGeoIp } = useGeoip();
  const { create } = useCreateSession();

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    minWidth: 800,
    minHeight: 500,
    padding: 2,
  };

  const createDisabled = false;

  const createNewSession = async () => {
    const geoip = await getGeoIp();

    setCreating(true);

    const response = await create(
      [url],
      "shared",
      EViewMode.Kiosk,
      webrtcDesktopVersion || null,
      null,
      JSON.stringify(geoip),
      count,
      ttl,
      codec
    );

    switch (stripKotlinPackageName(response.type)) {
      case "CreatedMirrorTabSession":
        window.open(
          `session/${response.session?.coordinates.namespace}/${response.session?.coordinates.id}`
        );
        break;
      case "CreatedMirrorTabSessionAsync":
        break;
    }

    setCreating(false);
  };

  const feedbackModal = (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <DialogTitle style={{ fontSize: 30, fontWeight: 3 }}>
            Create session
          </DialogTitle>
          <DialogContent>
            <Box marginTop={2}>
              <InfoTable
                rows={[
                  [
                    "URL",
                    <TextField
                      placeholder="URL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      id="input_url"
                      size="small"
                      required
                    />,
                  ],
                  [
                    "Engine version",
                    <TextField
                      placeholder="leave blank for default"
                      value={webrtcDesktopVersion}
                      onChange={(e) => setWebrtcDesktopVersion(e.target.value)}
                      id="webrtc_desktop_version"
                      size="small"
                    />,
                  ],
                  [
                    "Codec",
                    <Select
                      value={codec}
                      onChange={(e) => setCodec(e.target.value)}
                      size="small"
                    >
                      <MenuItem value="aurora4">aurora4</MenuItem>
                      <MenuItem value="x264">x264</MenuItem>
                    </Select>,
                  ],
                  [
                    "TTL",
                    <Select
                      value={ttl}
                      onChange={(e) => setTtl(e.target.value)}
                      size="small"
                    >
                      <MenuItem value={String(60 * 60)}>One hour</MenuItem>
                      <MenuItem value={String(60 * 60 * 8)}>
                        Eight hours
                      </MenuItem>
                      <MenuItem value={String(60 * 60 * 24)}>One day</MenuItem>
                      <MenuItem value={String(60 * 60 * 24 * 7)}>
                        One week
                      </MenuItem>
                    </Select>,
                  ],
                ]}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={creating || createDisabled}
              variant={"contained"}
              autoFocus
              onClick={createNewSession}
            >
              {creating ? <CircularProgress /> : "Create"}
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );

  return <>{feedbackModal}</>;
};

export const Sessions: React.FC = () => {
  const { webAppConfig } = useConfigContext();
  const environment = webAppConfig.environment();
  const isDebug = webAppConfig.isDebug();

  const navigate = useNavigate();
  const [creating] = React.useState(false);
  const deletingSessions = useList<string>([]);
  const deletedSessions = useList<string>([]);

  const { deleteSession } = useDeleteSession();
  const [selected, setSelected] = React.useState<string[]>([]);

  const addSelected = (id: string) => {
    setSelected([...new Set([...selected, id])]);
  };

  const removeSelected = (id: string) => {
    setSelected(selected.filter((i) => i !== id));
  };

  const { fetch, sessions } = useSessionsList();

  React.useEffect(() => {
    const fetchPeriodically = async () => {
      await fetch();
      setTimeout(() => fetchPeriodically(), 1000 * 3);
    };

    fetchPeriodically();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSelected = () => {
    selected.forEach((id) => {
      performDeleteSession(id, environment);
    });
  };

  const navigateDetails = (id: string) => {
    navigate(`/app/session/${environment}/${id}/debug`);
  };

  const selectAll = (checked: boolean) => {
    if (!checked) {
      setSelected([]);
    } else {
      setSelected(sessionsToList.map((s) => s.coordinates.id));
    }
  };

  const performDeleteSession = async (id: string, namespace: string) => {
    deletingSessions.add(id);
    await deleteSession(namespace, id);
    deletingSessions.remove(id);
    deletedSessions.add(id);
  };

  const sessionsToList = sessions
    .filter((item) => !deletedSessions.contains(item.coordinates.id))
    .sort((a, b) => b.created - a.created);

  const [createSessionOpen, setCreateSessionOpen] = React.useState(false);

  return (
    <>
      <CreateSessionModal
        open={createSessionOpen}
        onClose={() => setCreateSessionOpen(false)}
      />
      <Box>
        <Box>
          <Button
            style={{ height: 50 }}
            onClick={() => setCreateSessionOpen(true)}
            variant="contained"
            id="btn_create_new_session"
          >
            Create new session
          </Button>
        </Box>
        {isDebug ? (
          <Box>
            <Button
              color="error"
              variant="outlined"
              onClick={() => deleteSelected()}
            >
              Delete selected
            </Button>
          </Box>
        ) : null}
        <Card>
          <Box marginTop={2}>
            <Paper>
              <Table size="small" id="sessions-table">
                <TableHead>
                  <TableRow>
                    {isDebug && (
                      <TableCell style={{ width: 10 }}>
                        <Checkbox
                          onChange={(e) => selectAll(e.target.checked)}
                        />
                      </TableCell>
                    )}
                    <TableCell {...bold}>ID</TableCell>
                    <TableCell {...bold}>Created</TableCell>
                    <TableCell {...bold}>Website</TableCell>
                    <TableCell {...bold}>View Mode</TableCell>
                    {isDebug && <TableCell {...bold}>Engine</TableCell>}
                    <TableCell {...bold}>Expires</TableCell>
                    {isDebug && <TableCell {...bold}></TableCell>}
                    <TableCell {...bold}></TableCell>
                    <TableCell {...bold}></TableCell>
                  </TableRow>
                </TableHead>
                {creating ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : null}
                {sessionsToList.map((session, index) => (
                  <TableRow>
                    {isDebug && (
                      <TableCell style={{ width: 10 }}>
                        <Checkbox
                          checked={
                            selected.indexOf(session.coordinates.id) > -1
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              addSelected(session.coordinates.id);
                            } else {
                              removeSelected(session.coordinates.id);
                            }
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell id={`session_id_${index}`}>
                      <code>{session.coordinates.id}</code>{" "}
                      {isDebug ? (
                        <Button
                          onClick={() =>
                            navigateDetails(session.coordinates.id)
                          }
                          variant="outlined"
                          size="small"
                        >
                          <Launch fontSize="small" />
                        </Button>
                      ) : null}
                    </TableCell>
                    <TableCell id={`session_created_${index}`}>
                      {moment(session.created).fromNow()}
                    </TableCell>
                    <TableCell id={`session_website_${index}`}>
                      {session.urls[0]}
                    </TableCell>
                    <TableCell id={`session_view_mode_${index}`}>
                      {session.viewMode ?? EViewMode.Tabbed}
                    </TableCell>
                    {isDebug && (
                      <TableCell id={`session_version`}>
                        <a
                          href={`https://gitlab.com/mirrortab/monorepo/-/commit/${session.webrtcDesktopVersion}`}
                        >
                          {session.webrtcDesktopVersion}
                        </a>
                      </TableCell>
                    )}
                    <TableCell id={`session_website_${index}`}>
                      {moment(session.expires).fromNow()}
                    </TableCell>
                    {isDebug && (
                      <TableCell id={`session_bootup_${index}`}>
                        {session.timeToUsefulSession}
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        id={`session_details_${index}`}
                        variant="contained"
                        onClick={() => {
                          window.open(
                            `/app/session/${session.coordinates.namespace}/${session.coordinates.id}`
                          );
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      {deletingSessions.contains(session.coordinates.id) ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          id={`session_delete_${index}`}
                          variant="outlined"
                          onClick={() =>
                            performDeleteSession(
                              session.coordinates.id,
                              session.coordinates.namespace
                            )
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Paper>
          </Box>
        </Card>
      </Box>
    </>
  );
};

const stripKotlinPackageName = (input: string) =>
  input.replace("com.mirrortab.metadata.model.", "");
